import logo from './logo.svg';
import image from './image/centericon.JPG'
import images from './image/four.jpg'
import suit from './image/Suit 3.jpg'
import nobackground from './image/menuicon.JPG'
import footer from './image/footer.JPG'
import isologo from './logoImage/iso_logo.jpg'
import news from './image/new.PNG'
import icons from './image/icons.JPG'
import top from './image/Top.jpg'
import b from './image/b.jpeg'
// import one from './image/one.png'
import maps from './image/map.jpg'
import locations from './image/distance.jpg'
import three from './image/301.png'
// import twofloor from './image/2FLOOR.jpg'
import twofloor from './interior_image/In1.jpg'
import './App.css';
import { BiSearchAlt2, BiArrowBack } from 'react-icons/bi'
import { BsWhatsapp, BsFacebook } from 'react-icons/bs'
import { RiMenu5Fill, RiMenu3Line } from 'react-icons/ri'
import { useState, useEffect, useRef } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import mobileFaci from '../src/image/mobileFaci.jpg'
import mobilemap from '../src/image/mobilemap.jpg'
// STANDARD 2 BEDROOMS
import B306 from '../src/standard_2_bedroom/B306.png'
import B307 from '../src/standard_2_bedroom/B307.png'
import B308 from '../src/standard_2_bedroom/B308.png'
import B808 from '../src/standard_2_bedroom/B808.png'
import B809 from '../src/standard_2_bedroom/B809.png'
import B906 from '../src/standard_2_bedroom/B906.png'
import B907 from '../src/standard_2_bedroom/B907.png'
import Carousel from 'react-bootstrap/Carousel';

// PREMIRE 2 BEDROOMS
import B404 from '../src/premier_2_bedrooms/B404.png'
import B405 from '../src/premier_2_bedrooms/B405.png'
import B406 from '../src/premier_2_bedrooms/B406.png'
import B407 from '../src/premier_2_bedrooms/B407.png'
import B504 from '../src/premier_2_bedrooms/B504.png'
import B505 from '../src/premier_2_bedrooms/B505.png'
import B506 from '../src/premier_2_bedrooms/B506.png'
import B507 from '../src/premier_2_bedrooms/B507.png'
import B604 from '../src/premier_2_bedrooms/B604.png'
import B605 from '../src/premier_2_bedrooms/B605.png'
import B606 from '../src/premier_2_bedrooms/B606.png'
import B607 from '../src/premier_2_bedrooms/B607.png'
import B704 from '../src/premier_2_bedrooms/B704.png'
import B705 from '../src/premier_2_bedrooms/B705.png'
import B706 from '../src/premier_2_bedrooms/B706.png'
import B707 from '../src/premier_2_bedrooms/B707.png'


// STANDARD 1 BEDROOMS
import B301 from '../src/standard_1_bedroom/B301.png'
import B302 from '../src/standard_1_bedroom/B302.png'
import B304 from '../src/standard_1_bedroom/B304.png'
import B305 from '../src/standard_1_bedroom/B305.png'
import B402 from '../src/standard_1_bedroom/B402.png'
import B502 from '../src/standard_1_bedroom/B502.png'
import B602 from '../src/standard_1_bedroom/B602.png'
import B702 from '../src/standard_1_bedroom/B702.png'
import B802 from '../src/standard_1_bedroom/B802.png'
import B806 from '../src/standard_1_bedroom/B806.png'
import B807 from '../src/standard_1_bedroom/B807.png'
import B902 from '../src/standard_1_bedroom/B902.png'
import B904 from '../src/standard_1_bedroom/B904.png'
import B905 from '../src/standard_1_bedroom/B905.png'

// PREMIER 1 BEDROOMS
import B303 from '../src/premier_1_bedroom/B303.png'
import B403 from '../src/premier_1_bedroom/B403.png'
import B408 from '../src/premier_1_bedroom/B408.png'
import B503 from '../src/premier_1_bedroom/B503.png'
import B508 from '../src/premier_1_bedroom/B508.png'
import B603 from '../src/premier_1_bedroom/B603.png'
import B608 from '../src/premier_1_bedroom/B608.png'
import B703 from '../src/premier_1_bedroom/B603.png'
import B708 from '../src/premier_1_bedroom/B608.png'
import B805 from '../src/premier_1_bedroom/B805.png'

// executive suit 3 bedrooms
import B444 from '../src/executive_suit_3_bedrooms/B444.png'
import B555 from '../src/executive_suit_3_bedrooms/B555.png'
import B666 from '../src/executive_suit_3_bedrooms/B666.png'
import B777 from '../src/executive_suit_3_bedrooms/B777.png'
import B888 from '../src/executive_suit_3_bedrooms/B888.png'
import B999 from '../src/executive_suit_3_bedrooms/B999.png'

// duplex 3 bedrooms
import B803 from '../src/duplex_3_bedroom/B803.png'
import B804 from '../src/duplex_3_bedroom/B804.png'
import B810 from '../src/duplex_3_bedroom/B810.png'
import B811 from '../src/duplex_3_bedroom/B811.png'



// icondollars
import icondollars from '../src/image/iconsdollar.JPG'
import data from '../src/image/data.JPG'
import oneder from '../src/image/Icon.png'
import crop from '../src/image/crop.jpg'

// interior image
import in1 from '../src/interior_image/In1.jpg'
import in2 from '../src/interior_image/In2.jpg'
import in3 from '../src/interior_image/In3.jpg'
import in4 from '../src/interior_image/In4.jpg'
import in5 from '../src/interior_image/In5.jpg'
import in6 from '../src/interior_image/In6.jpg'
import in7 from '../src/interior_image/In7.jpg'
import in8 from '../src/interior_image/In8.jpg'
import in9 from '../src/interior_image/In9.jpg'
import in10 from '../src/interior_image/In10.jpg'
import in11 from '../src/interior_image/In11.jpg'
import in12 from '../src/interior_image/In12.jpg'
import in13 from '../src/interior_image/In13.jpg'
import in14 from '../src/interior_image/In14.jpg'
import in15 from '../src/interior_image/In15.jpg'
import in16 from '../src/interior_image/In16.jpg'
import in17 from '../src/interior_image/In17.jpg'
import ex1 from '../src/exterior_image/Ex1.jpg'
import ex2 from '../src/exterior_image/Ex2.jpg'
import ex3 from '../src/exterior_image/Ex3.jpg'
// facilities
import faci1 from '../src/facilities_image/cafe.jpg'
import faci2 from '../src/facilities_image/golf.jpg'
import faci3 from '../src/facilities_image/fitness.jpg'
import faci4 from '../src/facilities_image/pool.jpg'
import faci5 from '../src/facilities_image/coworking.jpg'

// facilities in mobile
import famo1 from '../src/facilities_mobile_image/Facility1.jpg'
import famo2 from '../src/facilities_mobile_image/Facility2.jpg'
import famo3 from '../src/facilities_mobile_image/Facility3.jpg'
import famo4 from '../src/facilities_mobile_image/Facility4.jpg'
import famo5 from '../src/facilities_mobile_image/Facility5.jpg'
import famo6 from '../src/facilities_mobile_image/Facility6.jpg'
import famo7 from '../src/facilities_mobile_image/Facility7.jpg'
import famo8 from '../src/facilities_mobile_image/Facility8.jpg'

// backgroundcolor
import back from '../src/image/back.jpg'
import back360 from '../src/image/back360.png'
import back360mb from '../src/image/back360mb.jpg'
import leftImage from '../src/image/leftimage.jpg'
// freeMobileImage
import Free1 from '../src/free_mobile_image/Free1.png'
import Free2 from '../src/free_mobile_image/Free2.png'
import Free3 from '../src/free_mobile_image/Free3.png'
// gunrantee in mobile
import gunmobile from '../src/image/gunmobile.JPG'
import video from '../src/video/video.mp4'
import line from '../src/image/line.JPG'
import slide1 from '../src/image/slide1.jpg'
import slide2 from '../src/image/slide2.jpg'
import slide3 from '../src/image/slide3.jpg'
import { BrowserView, MobileView } from 'react-device-detect'
import "./fonts/Cambria.ttf"
import "./fonts/GothamMedium.ttf"
import "./fonts/BASKVILL.ttf"
import "./fonts/Anziano-Regular.otf"
import "./fonts/NotoSansLao-Medium.ttf"
import emailjs from '@emailjs/browser';
import { Toast } from 'react-bootstrap';
import { toaster } from 'evergreen-ui'

function App() {

  // const [windowSize, setWindowSize] = useState(getWindowSize());
  const [type1, setType1] = useState('type: STANDARD 1 BEDROOM');
  const [type2, setType2] = useState('');
  const [room, setRoom] = useState('B301');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('interior');
  const [fullscreen, setFullscreen] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [one, setOne] = useState('');
  const [two, setTwo] = useState('');
  const [three, setThree] = useState('');
  const [four, setFour] = useState('');

  const Brochure_Url = 'http://localhost:3000/brochure2023.pdf';

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onButtonClick = (pdf) => {
    // using Java Script method to get PDF file
    fetch(`${pdf}.pdf`).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${pdf}.pdf`;
        alink.click();
      })
    })
  }
  const Ontop = () => {
    window.scrollTo(0, 0)

  }


  const projectconcept = useRef(null);
  const register = useRef(null);
  const facilities = useRef(null);
  const floorplan = useRef(null);
  const gallary = useRef(null);
  const investment = useRef(null);
  const location = useRef(null)
  const projectClick = () => {
    projectconcept.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const RegisterClick = () => {
    register.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const FacilitiesClick = () => {
    facilities.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const FloorplanClick = () => {
    floorplan.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const GallaryClick = () => {
    gallary.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const InvestmenClick = () => {
    investment.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const LocationClick = () => {
    location.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!one || !two || !three || !four) {
      // setShowToast(true)
      toaster.warning('Please Complete Information')
      return;
    }
    emailjs.sendForm('service_oemn7g9', 'template_maf33zs', e.target, 'LoQVZSeqpgPvpzpcs')
      .then((result) => {
        console.log(result.text);
        // setShowToast1(true);
        toaster.success('Successful')
        setOne('');
        setTwo('');
        setThree('');
        setFour('');
      }, (error) => {
        console.log(error.text);
      });
  };
  useEffect(() => {
    if (type1 === 'type: STANDARD 1 BEDROOM') {
      setRoom('B301')
    }
    if (type1 === 'type: PREMIER 1 BEDROOM') {
      setRoom('B303')
    }
    if (type1 === 'type: STANDARD 2 BEDROOMS') {
      setRoom('B306')
    }
    if (type1 === 'type: PREMIER 2 BEDROOMS') {
      setRoom('B404')
    }
    if (type1 === 'type: EXECUTIVE SUIT 3 BEDROOMS') {
      setRoom('B444')
    }
    if (type1 === 'type: DUPLEX 3 BEDROOMS') {
      setRoom('B803')
    }
  }, [type1])
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <div>
      <BrowserView>
        {/* Menu bar */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'fixed',
          width: '100%', backgroundColor: 'white', zIndex: 20,
          paddingTop: 10, paddingBottom: 10,

        }}>
          {
            showToast === true && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%', position: 'fixed',
                alignItems: 'center',
                paddingTop: 80, borderRadius: 5
              }}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                  paddingTop: 10, paddingBottom: 10
                }}>
                  <Toast.Header>
                    <strong className="me-auto" style={{ color: 'red', fontFamily: 'bas' }}> Please complete information !</strong>
                  </Toast.Header>
                </Toast>
              </div>
            )
          }
          {
            showToast1 === true && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%', position: 'fixed',
                alignItems: 'center',
                paddingTop: 80, borderRadius: 5
              }}>
                <Toast onClose={() => setShowToast1(false)} show={showToast1} delay={3000} autohide style={{
                  paddingTop: 10, paddingBottom: 10
                }}>
                  <Toast.Header>
                    <strong className="me-auto" style={{ color: '#8A744B', fontFamily: 'bas' }}> Successful !</strong>
                  </Toast.Header>
                </Toast>
              </div>
            )
          }

          <div style={{
            height: 70, flex: 1,
            paddingLeft: 50,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}>
            <BiSearchAlt2 size={34} color='#000' />
            {/* <h1>width: {windowSize.innerWidth}</h1>
            <h1>height: {windowSize.innerHeight}</h1> */}
          </div>
          <div style={{

            height: 70, flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img
              src={image} style={{
                width: 150, height: 60
              }}
              onClick={Ontop}
            />
          </div>
          <div style={{

            height: 70, flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center', alignContent: 'center',
            paddingRight: 30, fontFamily: 'got',
            cursor: 'pointer'
          }} onClick={Ontop}>
            <RiMenu3Line size={24} />
            <span>MENU</span>
          </div>
        </div>
        <div style={{ height: 70 }}></div>


        {/* Manee residence */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          position: 'absolute', width: '100%', zIndex: 10,
          height: windowSize.innerWidth <= 1350 ? 500 : 550

        }}>
          <div style={{
            backgroundColor: '#003044',
            height: windowSize.innerWidth <= 1350 ? 500 : 550, width: '55%', fontFamily: 'Cambria',
            color: 'white',
            paddingTop: 170
          }}>
            <div style={{ textAlign: 'center' }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 40, fontFamily: 'bas',
                justifyContent: 'center'
              }}>
                <span style={{ marginLeft: 5 }}>M</span>
                <span style={{ marginLeft: 5 }}>A</span>
                <span style={{ marginLeft: 5 }}>N</span>
                <span style={{ marginLeft: 5 }}>E</span>
                <span style={{ marginLeft: 5 }}>E</span>
                <span style={{ marginLeft: 25 }}>R</span>
                <span style={{ marginLeft: 5 }}>E</span>
                <span style={{ marginLeft: 5 }}>S</span>
                <span style={{ marginLeft: 5 }}>I</span>
                <span style={{ marginLeft: 5 }}>D</span>
                <span style={{ marginLeft: 5 }}>E</span>
                <span style={{ marginLeft: 5 }}>N</span>
                <span style={{ marginLeft: 5 }}>C</span>
                <span style={{ marginLeft: 5 }}>E</span>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <span style={{ fontSize: 20, borderBottom: '1px solid white', width: 50, height: 1 }}></span><br />
                <div style={{ width: 5 }} />
                <span style={{ fontSize: 20 }}>CONDOMINIUM</span><br />
                <div style={{ width: 5 }} />
                <span style={{ fontSize: 20, borderBottom: '1px solid white', width: 50, height: 1 }}></span><br />
              </div>
            </div>
            <div style={{
              textAlign: 'center',
              color: '#B89B6A',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: 40

            }}>
              <span style={{ fontSize: 30, fontWeight: 'bold', fontFamily: 'bas' }}></span><br />
              <div style={{ width: 20 }} />
              <span style={{ fontSize: 50, marginTop: -20, fontFamily: 'asia' }}></span><br />
              <span style={{ fontSize: 50, fontFamily: 'asia', marginTop: -20 }}></span><br />
            </div>
            <div style={{
              textAlign: 'center',
              color: '#fff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: 40, alignItems: 'center',
              fontFamily: 'got', alignContent: 'center'
            }}>
              <BsWhatsapp />
              <div style={{ width: 10 }} />
              <span>020 5999 1555</span>
              <div style={{ width: 20 }} />
              <BsFacebook />
              <div style={{ width: 10 }} />
              <span>Manee Residence</span>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'row', margin: 20,
              fontFamily: 'got', color: 'white',
              marginTop: -340, justifyContent: 'space-between',

            }}>
              <div style={{ width: windowSize.innerWidth <= 1350 ? 60 : 100 }}>
                <img src={nobackground} style={{ width: 140, height: 70, marginTop: -10 }} />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingLeft: 150, fontSize: 16
              }}>
                <div style={{
                  display: 'flex', justifyContent: 'flex-start',
                  flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer',
                  paddingLeft: 10, paddingRight: 10
                }}
                  onClick={projectClick}
                >
                  <span>PROJECT</span>
                  <span>CONCEPT</span>
                </div>
                <div style={{ width: 50 }}></div>
                <div style={{
                  display: 'flex', justifyContent: 'flex-start',
                  flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer',
                  paddingLeft: 10, paddingRight: 10
                }}
                  onClick={RegisterClick}
                >
                  <span>APPOINTMENT</span>
                  <span>REGISTRATION</span>
                </div>
                <div style={{ width: 50 }}></div>
                <div style={{
                  display: 'flex', justifyContent: 'flex-start',
                  flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer',
                  paddingLeft: 10, paddingRight: 10
                }}
                  onClick={FacilitiesClick}
                >
                  <span>FACILITIES</span>
                </div>
              </div>

            </div>
          </div>
          <div style={{

            height: 600, flex: 1
          }}>
            <img
              src={leftImage} style={{
                height: windowSize.innerWidth <= 1350 ? 500 : 550,
                width: '100%'
              }}
            />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontFamily: 'got', color: 'white',
              marginTop: windowSize.innerWidth <= 1350 ? -460 : -510, paddingLeft: 50,
              paddingRight: 50,
              fontFamily: 'got', fontSize: 16
            }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }}
                onClick={FloorplanClick}
              >
                <span>FLOOR</span>
                <span>PLAN</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }}
                onClick={GallaryClick}
              >
                <span>GALLERY</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }}
                onClick={InvestmenClick}
              >
                <span>INVESTMENT</span>
                <span>PROGRAM</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }}
                onClick={LocationClick}
              >
                <span>LOCATION</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: windowSize.innerWidth <= 1350 ? 500 : 550 }} />


        {/* 360 Maneeresidence */}

        <div className='content360'>
            <img src={back360} className='img360'  />
        </div>
        <div className='content360link'>
          <a href="https://360.maneeresidence.com/">
            <span className='btnLink360'>VIEW 360 INSIDE THE ROOMS</span>
          </a>
        </div>
        <div style={{ height: 345 }} />


        {/* Project concept */}
        <div style={{
          height: 500,
          position: 'absolute',
          width: '100%', zIndex: 15, backgroundColor: 'white'
        }} >
          <img src={top} style={{
            height:
              windowSize.innerWidth <= 1600 ? 800 : 900,
            width: '100%'
          }} />
          <div style={{ width: 3 }} />
          <div style={{
            paddingLeft: 50, fontFamily: 'Cambria',
            marginTop: windowSize.innerWidth <= 1600 ? -780 : -850, fontFamily: 'bas'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 48, color: 'white'
            }}>
              <span style={{}}>P</span>
              <span style={{ marginLeft: 5 }}>R</span>
              <span style={{ marginLeft: 5 }}>O</span>
              <span style={{ marginLeft: 5 }}>J</span>
              <span style={{ marginLeft: 5 }}>E</span>
              <span style={{ marginLeft: 5 }}>C</span>
              <span style={{ marginLeft: 5 }}>T</span>
              <span style={{ marginLeft: 5, marginLeft: 20 }}>C</span>
              <span style={{ marginLeft: 5 }}>O</span>
              <span style={{ marginLeft: 5 }}>N</span>
              <span style={{ marginLeft: 5 }}>C</span>
              <span style={{ marginLeft: 5 }}>E</span>
              <span style={{ marginLeft: 5 }}>P</span>
              <span style={{ marginLeft: 5 }}>T</span>
            </div>
            <div style={{
              color: 'white', fontFamily: 'noto',
              display: 'flex', flexDirection: 'column',
              fontSize: 16, paddingTop: windowSize.innerWidth <= 1600 ? 20 : 30
            }}>
              <span>ແນວຄວາມຄິດໂຄງການ  ມະນີ ຣີຊີເດັ້ນ ຄອນໂດມິນ້ຽມ </span>
              <div style={{ height: 16 }} />
              <span style={{ fontSize: 28 }}>"ຄອນໂດມິຕິໃໝ່ຂອງການຢູ່ອາໃສ"</span>
              <div style={{ height: 16 }} />
              <span>ມະນີ ຣີຊິເດັ້ນ ສ້າງຂື້ນພາຍໃຕ້ແນວຄິດທັນສະໄໝ ຫລູຫລາ</span>
              <div style={{ height: 10 }} />
              <span> ແລະ ສະດວກສະບາຍແກ່ຜູ້ພັກອາໃສໃນໃຈກາງເມືອງໂດຍນຳໃຊ້ </span>
              <div style={{ height: 10 }} />
              <span>ເທັກໂນໂລຢີສະໄໝໃໝ່ ເຊີ່ງຄຳນຶງເຖິງສິ່ງແວດລ້ອມເປັນສຳຄັນ, </span>
              <div style={{ height: 10 }} />
              <span>ເຮົາໃສ່ໃຈທຸກລາຍລະອຽດບໍ່ວ່າການຈັດສັນພື້ນທີ່ໃຊ້ສອຍໃຫ້ມີການ</span>
              <div style={{ height: 10 }} />
              <span>ແບ່ງຫ້ອງເພື່ອຈັດວາງເຄື່ອງໃຊ້ຕ່າງໆຢ່າງລົງຕົວເນັ້ນສ້າງຄວາມ </span>
              <div style={{ height: 10 }} />
              <span>ສະດວກສະບາຍໃນການຢູ່ອາໃສໄລຍະຍາວ ສອດຄ່ອງກັບການໃຊ້</span>
              <div style={{ height: 10 }} />
              <span>ຊີວິດປະຈຳວັນຂອງຄົນໃນສັງຄົມເມືອງ.
              </span>
              <div style={{ height: 10 }} />
              <span>ພາຍໃນໂຄງການຍັງມີສິ່ງອຳນວຍຄວາມສະດວກເຊັ່ນ: ສະລອຍນໍ້າ </span>
              <div style={{ height: 10 }} />
              <span>ລະບົບນາໂນເຕັກ, ແຜງໄຟຟ້າໂຊລ່າເຊວ,ບ່ອນຈອດລົດສ່ວນຕົວ,</span>
              <div style={{ height: 10 }} />
              <span>ຫ້ອງຊ້ອມຕີກ໋ອບ, ຫ້ອງອອກກຳລັງກາຍຂະໜາດໃຫຍ່,</span>
              <div style={{ height: 10 }} />
              <span>ຮ້ານອາຫານເພື່ອສຸຂະພາບ, ບໍລິການຊັກລິດ ແລະ ຮ້ານສະດວກຊື້.</span>
              <div style={{ height: 10 }} />
              <span>ຕົວອາຄານສ້າງດ້ວຍມາດຕະຖານທາງວິສະວະກຳລະດັບສູງ</span>
              <div style={{ height: 10 }} />
              <span>ແລະ ຜ່ານການຮັບຮອງຕາມມາດຕະຖານ ISO 9001.</span>
            </div>
            {/* <span style={{ fontSize: 40, color: 'white' }}>PROJECT CONCEPT</span> */}

          </div>
          <div style={{
            position: 'absolute',
            top: windowSize.innerWidth <= 1600 ? 500 : 600, paddingLeft: 50, fontFamily: 'Cambria',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1, width: '92%'
          }}>
            <div style={{

              flex: 1,
              display: 'flex', alignItems: 'flex-start',
              justifyContent: 'flex-end',
              flexDirection: 'column', color: 'white', fontFamily: 'got'
            }}>
              <button style={{

                borderRadius: 3, backgroundColor: 'transparent',
                height: 40, color: 'white',
                paddingLeft: 30, paddingRight: 30, border: '1px solid #BFA071',
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', alignContent: 'center'

              }} onClick={() => onButtonClick('digital')}>
                DOWNLOAD
              </button>
              <span style={{ fontSize: 16 }}>BROCHURE PDF</span>
            </div>
            <div style={{

              flex: 1, display: 'flex',
              color: 'white', flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
              <div style={{
                flex: 1, display: 'flex',
                flexDirection: 'column'
              }}>

              </div>
              <div style={{
                flex: 1, display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', fontFamily: 'got'
              }}>

                <div style={{ display: 'flex' }}>
                  <span style={{ fontSize: 22 }}>M</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>A</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>N</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>E</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>E</span>
                  <span style={{ fontSize: 22, marginLeft: 20 }}>R</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>E</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>S</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>I</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>D</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>E</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>N</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>C</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>E</span>

                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ fontSize: 22 }}>C</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>O</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>N</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>D</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>O</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>M</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>I</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>N</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>I</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>U</span>
                  <span style={{ fontSize: 22, marginLeft: 3 }}>M</span>

                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%', fontFamily: 'got'
                }}>
                  <div style={{
                    flex: 1, display: 'flex', flexDirection: 'column',
                    paddingTop: 10
                  }}>
                    <span>TENURE:</span>
                    <div style={{ height: 10 }} />
                    <span style={{}}>TOTAL AREA:</span>
                    <div style={{ height: 10 }} />
                    <span style={{}}>FLOORS:</span>
                    <div style={{ height: 10 }} />
                    <span style={{}}>TOTAL UNIT:</span>
                    <div style={{ height: 10 }} />
                    <span style={{}}>LIFTS:</span>
                    <div style={{ height: 10 }} />
                    <span style={{}}>CAR PARKING:</span>
                  </div>
                  <div style={{
                    flex: 1, display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column', alignItems: 'flex-end'
                  }}>
                    <span>FREEHOLD</span>
                    <div style={{ height: 10 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span >2.808 sqm</span>
                      <span style={{ marginTop: -10, fontSize: 12 }}>2</span>
                    </div>
                    <div style={{ height: 10 }} />
                    <span >9 floors</span>
                    <div style={{ height: 10 }} />
                    <span >59 units</span>
                    <div style={{ height: 10 }} />
                    <span >4 units</span>
                    <div style={{ height: 10 }} />
                    <span >64 lots</span>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div style={{ height: windowSize.innerWidth <= 1600 ? 1300 : 1400 }}></div>


        {/* free in room */}
        <div style={{ height: 0, width: '100%' }}>
          <img src={back} style={{ width: '100%', height: '100%', position: 'fixed', top: 0, zIndex: 0,border:'1px solid white' }} />
          <div style={{
            marginTop: -500, position: 'absolute',
            backgroundColor: 'rgba(0,0,0,.8)',
            width: '100%', height: 510, paddingTop: 100,
            paddingLeft: 50, paddingRight: 50,
          }}>
            <div style={{
              display: 'flex', width: '100%',
              justifyContent: 'center',
              color: 'white',
              fontFamily: 'bas',
              fontSize: 50, paddingBottom: 30
            }}>
              <div>
                <span style={{ marginLeft: 3 }}>F</span>
                <span style={{ marginLeft: 3 }}>R</span>
                <span style={{ marginLeft: 3 }}>E</span>
                <span style={{ marginLeft: 3 }}>E</span>
                <span style={{ marginLeft: 20 }}>I</span>
                <span style={{ marginLeft: 3 }}>N</span>
                <span style={{ marginLeft: 20 }}>R</span>
                <span style={{ marginLeft: 3 }}>O</span>
                <span style={{ marginLeft: 3 }}>O</span>
                <span style={{ marginLeft: 3 }}>M</span>
                <span style={{ marginLeft: 20 }}>D</span>
                <span style={{ marginLeft: 3 }}>E</span>
                <span style={{ marginLeft: 3 }}>C</span>
                <span style={{ marginLeft: 3 }}>O</span>
                <span style={{ marginLeft: 3 }}>R</span>
                <span style={{ marginLeft: 3 }}>A</span>
                <span style={{ marginLeft: 3 }}>T</span>
                <span style={{ marginLeft: 3 }}>I</span>
                <span style={{ marginLeft: 3 }}>O</span>
                <span style={{ marginLeft: 3 }}>N</span>

              </div>
            </div>

            <img src={oneder} style={{
              width: '100%'
            }} />
          </div>
          <div style={{ height: 300 }} ref={gallary}></div>
        </div>
        <div style={{ height: 0, border: '1px solid white' }} ></div>

        {/* gallary tag */}
        <div style={{
          backgroundColor: '#000',
          height: 100, display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute', width: '100%', zIndex: 10,
        }} >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 48, color: '#BFA071',
            fontFamily: 'bas', justifyContent: 'center', width: '100%',
            paddingLeft: windowSize.innerWidth <= 1350 ? 100 :
              windowSize.innerWidth <= 1450 ? 120 :
                windowSize.innerWidth <= 1550 ? 140 :
                  windowSize.innerWidth <= 1600 ? 160 :
                    windowSize.innerWidth <= 1650 ? 180 :
                      windowSize.innerWidth <= 1700 ? 200 : 220,
            paddingRight: windowSize.innerWidth <= 1350 ? 100 :
              windowSize.innerWidth <= 1450 ? 120 :
                windowSize.innerWidth <= 1550 ? 140 :
                  windowSize.innerWidth <= 1600 ? 160 :
                    windowSize.innerWidth <= 1650 ? 180 :
                      windowSize.innerWidth <= 1700 ? 200 : 220
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row', flex: 1,
              justifyContent: 'flex-end'
            }}>
              <span>G</span>
              <span style={{ marginLeft: 3 }}>A</span>
              <span style={{ marginLeft: 3 }}>L</span>
              <span style={{ marginLeft: 3 }}>L</span>
              <span style={{ marginLeft: 3 }}>E</span>
              <span style={{ marginLeft: 3 }}>R</span>
              <span style={{ marginLeft: 3 }}>Y</span>
            </div>

            <div style={{
              display: 'flex', flex: 1,
              alignItems: 'center',
              fontSize: 16, color: '#fff',
              fontFamily: 'got', justifyContent: 'flex-end'
            }}>
              <div style={{
                borderBottom: category === 'interior' ? '2px solid white' : '',
                color: category === 'interior' ? '#BFA071' : 'white', cursor: 'pointer'
              }} onClick={() => setCategory('interior')}>
                <span>INTERIOR</span>
              </div>
              <div style={{ width: '10%' }}></div>
              <div style={{
                borderBottom: category === 'exterior' ? '2px solid white' : '',
                color: category === 'exterior' ? '#BFA071' : 'white', cursor: 'pointer'
              }}  onClick={() => setCategory('exterior')}>
                <span>EXTERIOR</span>
              </div>
              <div style={{ width: '10%' }}></div>
              <div style={{
                borderBottom: category === 'vdo' ? '2px solid white' : '',
                color: category === 'vdo' ? '#BFA071' : 'white', cursor: 'pointer'
              }} onClick={() => setCategory('vdo')}>
                <span >VDO</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 100 }} />

        {/* exterior image */}
        {
          fullscreen === 'interior' && (
            <div style={{
              width: '100%', height: '100%',
              position: 'fixed', zIndex: 20, top: 0,
              backgroundColor: 'black', left: 0,
              paddingLeft: 150, paddingRight: 150
            }}>
              <div style={{ paddingTop: 20, paddingBottom: 20, color: 'white' }}>
                <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
              </div>
              <Carousel>
                <Carousel.Item interval={2000}>
                  <img
                    src={in1}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in2}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in3}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in4}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in5}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in6}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in7}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in8}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in9}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in10}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in11}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in12}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in13}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in14}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in15}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in16}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={in17}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>

              </Carousel>
            </div>
          )
        }
        {/* exteriori fullscreen */}
        {
          fullscreen === 'exterior' && (
            <div style={{
              width: '100%', height: '100%',
              position: 'fixed', zIndex: 20, top: 0,
              backgroundColor: 'black', left: 0,
              paddingLeft: 150, paddingRight: 150
            }}>
              <div style={{ paddingTop: 20, paddingBottom: 20, color: 'white' }}>
                <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
              </div>
              <Carousel>
                <Carousel.Item interval={2000}>
                  <img
                    src={ex1}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={ex2}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <img
                    src={ex3}
                    style={{
                      height: 680,
                      width: '100%',

                    }}
                  />
                </Carousel.Item>

              </Carousel>
            </div>
          )
        }
        {
          fullscreen === 'vdo' && (
            <div style={{
              width: '100%', height: '100%',
              position: 'fixed', zIndex: 20, top: 0,
              backgroundColor: 'black', left: 0,
              paddingLeft: 150, paddingRight: 150
            }}>
              <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
              </div>
              <video controls style={{ width: '100%', }}>
                <source src={video} />
              </video>
            </div>
          )
        }


        {/* fullscreen */}
        <div style={{
          backgroundColor: '#000',
          display: 'flex', flexDirection: 'column',
          position: 'absolute', zIndex: 10, width: '100%',
          justifyContent: 'center', alignItems: 'center',
          height: 775
        }}>
          <div style={{
            width: '100%', textAlign: 'center',
            paddingLeft: windowSize.innerWidth <= 1350 ? 100 :
              windowSize.innerWidth <= 1450 ? 120 :
                windowSize.innerWidth <= 1550 ? 140 :
                  windowSize.innerWidth <= 1600 ? 140 :
                    windowSize.innerWidth <= 1650 ? 180 :
                      windowSize.innerWidth <= 1700 ? 200 : 250,
            paddingRight: windowSize.innerWidth <= 1350 ? 100 :
              windowSize.innerWidth <= 1450 ? 120 :
                windowSize.innerWidth <= 1550 ? 140 :
                  windowSize.innerWidth <= 1600 ? 160 :
                    windowSize.innerWidth <= 1650 ? 180 :
                      windowSize.innerWidth <= 1700 ? 200 : 220
          }}>
            {
              category === 'vdo' && (
                <video controls style={{ width: '100%', height: 700, }}>
                  <source src={video} />
                </video>
              )
            }
            {
              category === 'exterior' && (
                <img src={ex1} style={{
                  width: '100%', height: 700, borderRadius: 3
                }} />
              )
            }
            {
              category === 'interior' && (
                <img src={twofloor} style={{
                  width: '100%', height: 700, borderRadius: 3
                }} />
              )
            }
          </div>
          <div style={{
            paddingTop: 20, paddingBottom: 20,
            display: 'flex',
            justifyContent: 'center'
          }}>
            {
              category === 'interior' && (
                <button style={{
                  fontFamily: 'got',
                  borderRadius: 3, backgroundColor: 'transparent',
                  height: 45, color: 'white',
                  paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                  display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                }} ref={facilities} onClick={() => setFullscreen('interior')}>

                  FULL SCREEN
                </button>
              )
            }
            {
              category === 'exterior' && (
                <button style={{
                  fontFamily: 'got',
                  borderRadius: 3, backgroundColor: 'transparent',
                  height: 45, color: 'white',
                  paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                  display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                }} ref={facilities} onClick={() => setFullscreen('exterior')}>

                  FULL SCREEN
                </button>
              )
            }
            {
              category === 'vdo' && (
                <button style={{
                  fontFamily: 'got',
                  borderRadius: 3, backgroundColor: 'transparent',
                  height: 45, color: 'white',
                  paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                  display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'
                }} ref={facilities} onClick={() => setFullscreen('vdo')}>
                  FULL SCREEN
                </button>
              )
            }

          </div>
        </div>
        <div style={{ height: 775 }} />

        {/* facilities */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute', width: '100%', zIndex: 5
        }} >

          <div style={{
            width: 550,
            backgroundColor: '#C2B193',
            paddingLeft: 50, fontFamily: 'Cambria',
            color: 'white',
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start',
            paddingTop: 30, flexDirection: 'column',
            paddingBottom: 20
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <span style={{ fontSize: 48, fontFamily: 'bas' }}>F</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>A</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>C</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>I</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>L</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>I</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>T</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>I</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>E</span>
              <span style={{ fontSize: 48, fontFamily: 'bas', marginLeft: 3 }}>S</span>
            </div>
            <div style={{ height: 12 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>SWIMMING POOL</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>FITNESS</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>STEAM ROOM</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>CO-WORKING SPACE</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>GOLF SIMULATOR ROOM</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>MEETING ROOM</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>GARDEN</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>FITNESS</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>PRIVATE PARKING LOT 100%</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>KID'S PLAYGROUND</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>LAUNDRY SERVICE</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>MANEE CAFE</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>CONVENIENCE STORE</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>MAIL-ROOM/MAIL BOXES</span>
            <div style={{ height: 10 }}></div>
            <span style={{ fontFamily: 'got', fontSize: 18 }}>24hrs. SECURITY and CCTV</span>
            <span ref={investment} style={{ color: '#C2B193' }}>24hrs. SECURITY and CCTV</span>
          </div>

          <div style={{
            flex: 1,
            backgroundColor: '#C2B193',
            paddingTop: windowSize.innerWidth <= 1350 ? 200 : 80
          }}>
            <Carousel indicators={false}>
              <Carousel.Item interval={2000}>
                <img
                  src={faci4}
                  style={{
                    height: windowSize.innerWidth <= 1350 ? 500 : 630,
                    width: '100%',
                    borderRadius: 5
                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={faci2}
                  style={{
                    height: windowSize.innerWidth <= 1350 ? 500 : 630,
                    width: '100%',
                    borderRadius: 5
                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={faci3}
                  style={{
                    height: windowSize.innerWidth <= 1350 ? 500 : 630,
                    width: '100%',
                    borderRadius: 5
                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={faci5}
                  style={{
                    height: windowSize.innerWidth <= 1350 ? 500 : 630,
                    width: '100%',
                    borderRadius: 5
                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={faci1}
                  style={{
                    height: windowSize.innerWidth <= 1350 ? 500 : 630,
                    width: '100%',
                    borderRadius: 5
                  }}
                />
              </Carousel.Item>

            </Carousel>
          </div>

        </div>
        <div style={{ height: 700 }} />

        {/* investment */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute', width: '100%', zIndex: 5,
          flex: 1
        }} >
          <div style={{
            backgroundColor: 'white',
            width: 550, fontFamily: 'bas',
            paddingTop: 20, paddingBottom: 100, zIndex: 1,
            textAlign: 'center'

          }}>
            <span style={{ color: '#666666', fontSize: 48, marginLeft: 190, marginTop: 5 }}>
              INVESTMENT
            </span>

            <img src={suit} style={{
              height: 500, width: 820, zIndex: 10,
              marginTop: 20
            }} />
          </div>
          <div
            style={{
              backgroundColor: '#003044',
              flex: 1,
              width: '100%'
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 80,
              paddingTop: 32,
              paddingLeft: 30
            }}>
              <span style={{ color: '#B2A68B', fontSize: 48, fontFamily: 'bas' }}>
                PROGRAM
              </span>

            </div>
            <div style={{
              display: 'flex',
              height: 550,
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1, width: '100%'
            }}>
              <div style={{
                flex: 1
              }}>

              </div>
              <div style={{
                flex: 1, paddingTop: 20
              }}>
                <span style={{ color: '#fff', fontSize: 48, fontFamily: 'cam' }}>
                  GUARANTEE
                </span>

                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: 'white',
                  fontFamily: 'cam'
                }}>
                  <div style={{
                    display: 'flex', flexDirection: 'column',
                    fontSize: 30
                  }}>
                    <span>ANNUAL</span>
                    <span style={{ marginTop: -10 }}>INTEREST</span>
                    <span style={{ marginTop: -10 }}>RETURN</span>

                  </div>

                  <div style={{
                    flex: 1, display: 'flex',
                    fontFamily: 'asia',
                    marginTop: -80, color: '#D2B37F'
                  }}>
                    <span style={{ fontSize: 150 }}>7</span>
                    <span style={{ fontSize: 120, marginTop: 50 }}>%</span>
                  </div>
                </div>
                <img src={icondollars} style={{ width: 150, height: 150, marginLeft: 50 }} /><br /><br />
                <img src={data} style={{ width: '80%', height: 200 }} />
              </div>
            </div>

            <div ref={floorplan}></div>
          </div>

        </div>
        <div style={{ height: 712 }} />


        {/* room floor plan */}
        <div style={{
          backgroundColor: '#C2B193',
          display: 'flex',
          justifyContent: 'space-between',
          color: 'white', position: 'absolute',
          width: '100%', zIndex: 10, paddingBottom: 10,
          height: 500
        }} >

          {/* area and floor */}
          <div style={{
            flex: 1, fontFamily: 'Cambria',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 50, flexDirection: 'column',
            alignItems: 'flex-start', paddingTop: 70
          }}>
            <span style={{
              fontSize: 48, fontFamily: 'bas'
            }}>ROOM FLOOR PLAN</span>
            <span style={{ fontSize: 24, fontFamily: 'got' }}>Room {type1}({room}).</span>
            {room === 'B301' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
            {room === 'B302' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
            {room === 'B303' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 61.45 sqm</span>)}
            {room === 'B304' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
            {room === 'B305' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
            {room === 'B402' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B502' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B602' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B702' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B802' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B806' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
            {room === 'B807' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
            {room === 'B902' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
            {room === 'B904' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
            {room === 'B905' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
            {/* {room === 'B303' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 61.45 sqm</span>)} */}
            {room === 'B403' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
            {room === 'B408' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
            {room === 'B503' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
            {room === 'B508' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
            {room === 'B603' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
            {room === 'B608' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
            {room === 'B703' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
            {room === 'B708' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
            {room === 'B805' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 62.91 sqm</span>)}
            {room === 'B306' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
            {room === 'B307' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.18 sqm</span>)}
            {room === 'B308' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 80.63 sqm</span>)}
            {room === 'B808' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
            {room === 'B809' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.18 sqm</span>)}
            {room === 'B906' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
            {room === 'B907' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 78.18 sqm</span>)}
            {room === 'B444' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B555' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B666' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B777' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B888' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B999' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
            {room === 'B404' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B405' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
            {room === 'B406' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
            {room === 'B407' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B803' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 168.59 sqm</span>)}
            {room === 'B804' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 156.34 sqm</span>)}
            {room === 'B810' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 156.34 sqm</span>)}
            {room === 'B811' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 168.54 sqm</span>)}
            {room === 'B504' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B505' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 162.64 sqm</span>)}
            {room === 'B506' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
            {room === 'B507' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B604' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B605' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
            {room === 'B606' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
            {room === 'B607' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B704' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
            {room === 'B705' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
            {room === 'B706' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
            {room === 'B707' && (<span style={{ fontSize: 24, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}




            {room === 'B301' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}
            {room === 'B302' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}
            {/* {room === 'B303' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)} */}
            {room === 'B304' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}
            {room === 'B305' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}
            {room === 'B402' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B502' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B602' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B702' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B802' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B806' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)} {room === 'B807' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)} {room === 'B902' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)} {room === 'B904' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B905' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B303' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}{room === 'B403' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B408' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B503' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B508' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B603' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B608' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B703' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B708' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B805' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B306' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}{room === 'B307' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}{room === 'B308' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>rd</span><br />
            </div>)}{room === 'B808' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B809' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B906' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B907' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B444' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B555' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B666' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B777' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B888' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B999' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B404' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B405' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B406' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B407' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B803' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B804' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B810' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B811' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}
            {room === 'B504' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B505' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B506' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B507' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B604' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B605' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B606' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B607' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B704' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B705' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B706' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}{room === 'B707' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontSize: 24, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
              <span style={{ fontSize: 16, fontFamily: 'got', marginTop: -4, marginLeft: -85 }}>th</span><br />
            </div>)}

            <div style={{ height: 20 }} />


            <select style={{
              border: '2px solid white',
              paddingTop: 15, paddingBottom: 15,
              width: windowSize.innerWidth <= 1350 ? '80%' : '60%',
              backgroundColor: 'transparent',
              outline: 'none', fontFamily: 'got',
              color: 'white',
              backgroundColor: '#C2B193',
              borderLeft: 'none',
              borderRight: 'none', fontSize: 20,
            }}
              onChange={(e) => setType1(e.target.value)}
              value={type1}
            >
              <option value={'type: STANDARD 1 BEDROOM'} style={{ height: 50 }} >TYPE: STANDARD 1 BEDROOM</option>
              <option value={'type: PREMIER 1 BEDROOM'} style={{ height: 50 }} >TYPE: PREMIER 1 BEDROOM</option>
              <option value={'type: STANDARD 2 BEDROOMS'} >TYPE: STANDARD 2 BEDROOMS</option>
              <option value={'type: PREMIER 2 BEDROOMS'} >TYPE: PREMIER 2 BEDROOMS</option>
              <option value={'type: EXECUTIVE SUIT 3 BEDROOMS'} >TYPE: EXECUTIVE SUIT 3 BEDROOMS</option>
              <option value={'type: DUPLEX 3 BEDROOMS'} >TYPE: DUPLEX 3 BEDROOMS</option>
            </select>
            <select style={{
              border: '2px solid white',
              paddingTop: 15, paddingBottom: 15,
              width: windowSize.innerWidth <= 1350 ? '80%' : '60%',
              backgroundColor: 'transparent',
              outline: 'none', fontFamily: 'got',
              color: 'white',
              backgroundColor: '#C2B193',
              borderLeft: 'none', fontSize: 20,
              borderRight: 'none', borderTop: 'none'
            }}
              onChange={(e) => setRoom(e.target.value)}
              value={room}
            >
              {
                type1 === 'type: STANDARD 1 BEDROOM' && (
                  <>
                    <option value={'B301'} style={{ paddingTop: 10 }}>ROOM NO: B301</option>
                    <option value={'B302'} style={{ paddingTop: 10 }}>ROOM NO: B302</option>
                    <option value={'B304'} style={{ paddingTop: 10 }}>ROOM NO: B304</option>
                    <option value={'B305'} style={{ paddingTop: 10 }}>ROOM NO: B305</option>
                    <option value={'B402'} style={{ paddingTop: 10 }}>ROOM NO: B402</option>
                    <option value={'B502'} style={{ paddingTop: 10 }}>ROOM NO: B502</option>
                    <option value={'B602'} style={{ paddingTop: 10 }}>ROOM NO: B602</option>
                    <option value={'B702'} style={{ paddingTop: 10 }}>ROOM NO: B702</option>
                    <option value={'B802'} style={{ paddingTop: 10 }}>ROOM NO: B802</option>
                    <option value={'B806'} style={{ paddingTop: 10 }}>ROOM NO: B806</option>
                    <option value={'B807'} style={{ paddingTop: 10 }}>ROOM NO: B807</option>
                    <option value={'B902'} style={{ paddingTop: 10 }}>ROOM NO: B902</option>
                    <option value={'B904'} style={{ paddingTop: 10 }}>ROOM NO: B904</option>
                    <option value={'B905'} style={{ paddingTop: 10 }}>ROOM NO: B905</option>
                  </>
                )
              }
              {
                type1 === 'type: PREMIER 1 BEDROOM' && (
                  <>
                    <option value={'B303'} style={{ paddingTop: 10 }}>ROOM NO: B303</option>
                    <option value={'B403'} style={{ paddingTop: 10 }}>ROOM NO: B403</option>
                    <option value={'B408'} style={{ paddingTop: 10 }}>ROOM NO: B408</option>
                    <option value={'B503'} style={{ paddingTop: 10 }}>ROOM NO: B503</option>
                    <option value={'B508'} style={{ paddingTop: 10 }}>ROOM NO: B508</option>
                    <option value={'B603'} style={{ paddingTop: 10 }}>ROOM NO: B603</option>
                    <option value={'B608'} style={{ paddingTop: 10 }}>ROOM NO: B608</option>
                    <option value={'B703'} style={{ paddingTop: 10 }}>ROOM NO: B703</option>
                    <option value={'B708'} style={{ paddingTop: 10 }}>ROOM NO: B708</option>
                    <option value={'B805'} style={{ paddingTop: 10 }}>ROOM NO: B805</option>
                  </>
                )
              }
              {
                type1 === 'type: STANDARD 2 BEDROOMS' && (
                  <>
                    <option value={'B306'} style={{ paddingTop: 10 }}>ROOM NO: B306</option>
                    <option value={'B307'} style={{ paddingTop: 10 }}>ROOM NO: B307</option>
                    <option value={'B308'} style={{ paddingTop: 10 }}>ROOM NO: B308</option>
                    <option value={'B808'} style={{ paddingTop: 10 }}>ROOM NO: B808</option>
                    <option value={'B809'} style={{ paddingTop: 10 }}>ROOM NO: B809</option>
                    <option value={'B906'} style={{ paddingTop: 10 }}>ROOM NO: B906</option>
                    <option value={'B907'} style={{ paddingTop: 10 }}>ROOM NO: B907</option>
                  </>
                )
              }
              {
                type1 === 'type: PREMIER 2 BEDROOMS' && (
                  <>
                    <option value={'B404'} style={{ paddingTop: 10 }}>ROOM NO: B404</option>
                    <option value={'B405'} style={{ paddingTop: 10 }}>ROOM NO: B405</option>
                    <option value={'B406'} style={{ paddingTop: 10 }}>ROOM NO: B406</option>
                    <option value={'B407'} style={{ paddingTop: 10 }}>ROOM NO: B407</option>
                    <option value={'B504'} style={{ paddingTop: 10 }}>ROOM NO: B504</option>
                    <option value={'B505'} style={{ paddingTop: 10 }}>ROOM NO: B505</option>
                    <option value={'B506'} style={{ paddingTop: 10 }}>ROOM NO: B506</option>
                    <option value={'B507'} style={{ paddingTop: 10 }}>ROOM NO: B507</option>
                    <option value={'B604'} style={{ paddingTop: 10 }}>ROOM NO: B604</option>
                    <option value={'B605'} style={{ paddingTop: 10 }}>ROOM NO: B605</option>
                    <option value={'B606'} style={{ paddingTop: 10 }}>ROOM NO: B606</option>
                    <option value={'B607'} style={{ paddingTop: 10 }}>ROOM NO: B607</option>
                    <option value={'B704'} style={{ paddingTop: 10 }}>ROOM NO: B704</option>
                    <option value={'B705'} style={{ paddingTop: 10 }}>ROOM NO: B705</option>
                    <option value={'B706'} style={{ paddingTop: 10 }}>ROOM NO: B706</option>
                    <option value={'B707'} style={{ paddingTop: 10 }}>ROOM NO: B707</option>
                  </>
                )
              }
              {
                type1 === 'type: EXECUTIVE SUIT 3 BEDROOMS' && (
                  <>
                    <option value={'B444'} style={{ paddingTop: 10 }}>ROOM NO: B444</option>
                    <option value={'B555'} style={{ paddingTop: 10 }}>ROOM NO: B555</option>
                    <option value={'B666'} style={{ paddingTop: 10 }}>ROOM NO: B666</option>
                    <option value={'B777'} style={{ paddingTop: 10 }}>ROOM NO: B777</option>
                    <option value={'B888'} style={{ paddingTop: 10 }}>ROOM NO: B888</option>
                    <option value={'B999'} style={{ paddingTop: 10 }}>ROOM NO: B999</option>
                  </>
                )
              }
              {
                type1 === 'type: DUPLEX 3 BEDROOMS' && (
                  <>
                    <option value={'B803'} style={{ paddingTop: 10 }}>ROOM NO: B803</option>
                    <option value={'B804'} style={{ paddingTop: 10 }}>ROOM NO: B804</option>
                    <option value={'B810'} style={{ paddingTop: 10 }}>ROOM NO: B810</option>
                    <option value={'B811'} style={{ paddingTop: 10 }}>ROOM NO: B811</option>
                  </>
                )
              }

            </select>
            <br />
            <span style={{ fontFamily: 'got', fontSize: 16 }} ref={location}>Disclaimer: Items are not finalised and may be changed without notice..</span>
          </div>

          {/* floor plan image */}
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', backgroundColor: 'transparent',
            paddingLeft: 10, paddingRight: 10
          }}>
            {/* STANDARD 1 BEDROOM */}
            {
              room === 'B301' && (
                <>
                  <img src={B301} style={{
                    width: 377, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 377, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B301')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B302' && (
                <>
                  <img src={B302} style={{
                    width: 374.117, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374.117, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B302')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B304' && (
                <>
                  <img src={B304} style={{
                    width: 374.846, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374.846, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B304')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B305' && (
                <>
                  <img src={B305} style={{
                    width: 380.546, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 380.546, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B305')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B402' && (
                <>
                  <img src={B402} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B402')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B502' && (
                <>
                  <img src={B502} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B502')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B602' && (
                <>
                  <img src={B602} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B602')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B702' && (
                <>
                  <img src={B702} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B702')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B802' && (
                <>
                  <img src={B802} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B802')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B806' && (
                <>
                  <img src={B806} style={{
                    width: 374, height: 188.255,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B806')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B807' && (
                <>
                  <img src={B807} style={{
                    width: 374.678, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374.678, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B807')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B902' && (
                <>
                  <img src={B902} style={{
                    width: 375.9, height: 204.05,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.9, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B902')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B904' && (
                <>
                  <img src={B904} style={{
                    width: 374.846, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374.846, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B904')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B905' && (
                <>
                  <img src={B905} style={{
                    width: 374.511, height: 172,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 374.511, fontSize: 18, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B905')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }

            {/* PREMIER 1 BEDROOM */}
            {
              room === 'B303' && (
                <>
                  <img src={B303} style={{
                    width: 338, height: 288.5,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 338, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B303')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B403' && (
                <>
                  <img src={B403} style={{
                    width: 337.5, height: 288.3,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.5, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B403')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B408' && (
                <>
                  <img src={B408} style={{
                    width: 337.382, height: 287,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.382, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B408')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B503' && (
                <>
                  <img src={B503} style={{
                    width: 337.5, height: 288.3,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.5, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B503')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B508' && (
                <>
                  <img src={B508} style={{
                    width: 337.382, height: 287,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.382, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B508')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B603' && (
                <>
                  <img src={B603} style={{
                    width: 337.5, height: 288.3,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.5, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B603')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B608' && (
                <>
                  <img src={B608} style={{
                    width: 337.382, height: 287,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.382, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B608')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B703' && (
                <>
                  <img src={B703} style={{
                    width: 337.5, height: 288.3,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.5, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B703')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B708' && (
                <>
                  <img src={B708} style={{
                    width: 337.382, height: 287,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.382, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B708')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B805' && (
                <>
                  <img src={B805} style={{
                    width: 378.84, height: 286.8,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 378.84, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B805')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }


            {/* STANDARD 2 BEDROOMS */}

            {
              room === 'B306' && (
                <>
                  <img src={B306} style={{
                    width: 351, height: 286.8,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 351, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B306')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B307' && (
                <>
                  <img src={B307} style={{
                    width: 336.6, height: 287.1,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 336.6, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B307')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B308' && (
                <>
                  <img src={B308} style={{
                    width: 341.1, height: 286.8,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 341.1, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B308')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B808' && (
                <>
                  <img src={B808} style={{
                    width: 352, height: 318.154,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 352, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B808')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B906' && (
                <>
                  <img src={B906} style={{
                    width: 339, height: 288.286,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 339, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B906')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B907' && (
                <>
                  <img src={B907} style={{
                    width: 337.2, height: 286.2,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 337.2, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B907')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {/* PREMIER 2 BEDROOMS */}
            {
              room === 'B404' && (
                <>
                  <img src={B404} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B404')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B405' && (
                <>
                  <img src={B405} style={{
                    width: 376, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 376, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B405')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B809' && (
                <>
                  <img src={B809} style={{
                    width: 351.38, height: 318.903,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 351.38, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B809')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B406' && (
                <>
                  <img src={B406} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B406')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B407' && (
                <>
                  <img src={B407} style={{
                    width: 367.129, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 367.129, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B407')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B504' && (
                <>
                  <img src={B504} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B504')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B505' && (
                <>
                  <img src={B505} style={{
                    width: 376, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 376, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B505')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B506' && (
                <>
                  <img src={B506} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B506')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B507' && (
                <>
                  <img src={B507} style={{
                    width: 367.129, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 367.129, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B507')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B604' && (
                <>
                  <img src={B604} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B604')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B605' && (
                <>
                  <img src={B605} style={{
                    width: 376, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 376, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B605')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B606' && (
                <>
                  <img src={B606} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B606')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B607' && (
                <>
                  <img src={B607} style={{
                    width: 367.129, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 367.129, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B607')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B704' && (
                <>
                  <img src={B704} style={{
                    width: 375.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 375.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B704')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B705' && (
                <>
                  <img src={B705} style={{
                    width: 367, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 367, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B705')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B706' && (
                <>
                  <img src={B706} style={{
                    width: 372.372, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 372.372, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B706')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B707' && (
                <>
                  <img src={B707} style={{
                    width: 367.129, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 367.129, fontSize: 16, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B707')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }


            {/* EXECUTIVE SUIT 3 BEDROOMS */}
            {
              room === 'B444' && (
                <>
                  <img src={B444} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B444')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B555' && (
                <>
                  <img src={B555} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B555')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B666' && (
                <>
                  <img src={B666} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B666')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B777' && (
                <>
                  <img src={B777} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B777')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B888' && (
                <>
                  <img src={B888} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B888')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B999' && (
                <>
                  <img src={B999} style={{
                    width: 441.31, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 441.31, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B999')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }

            {/* DUPLEX 3 BEDROOMS */}
            {
              room === 'B803' && (
                <>
                  <img src={B803} style={{
                    width: 600, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 600, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B803')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B804' && (
                <>
                  <img src={B804} style={{
                    width: 600, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 600, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B804')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B810' && (
                <>
                  <img src={B810} style={{
                    width: 600, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 600, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B810')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }
            {
              room === 'B811' && (
                <>
                  <img src={B811} style={{
                    width: 600, height: 300,
                    borderRadius: 3,
                    marginTop: 20
                  }}
                  />
                  <button style={{
                    fontFamily: 'got',
                    borderRadius: 3, backgroundColor: 'transparent',
                    height: 45, color: 'white', marginTop: 20,
                    paddingLeft: 30, paddingRight: 30, border: '1px solid white',
                    cursor: 'pointer', marginTop: 40,
                    width: 600, fontSize: 20, display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                  }} onClick={() => onButtonClick('B811')}>
                    DOWNLOAD ROOM FLOOR PLAN
                  </button>
                </>
              )
            }



          </div>
        </div>
        <div style={{ height: 500 }} />

        {/* google maps  */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute', zIndex: 5, width: '100%',
          backgroundColor: 'white', height: 520
        }} >
          <div style={{
            flex: 1,
            paddingLeft: 50,
            paddingTop: 50,
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
            <img src={maps} style={{
              width: 602.666,
              height: 385.874
            }} />
          </div>
          <div style={{
            flex: 1,
            paddingLeft: 50, paddingRight: 50,
            paddingTop: 50,display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}>
            <img src={locations} style={{
              width: 479,
              height: 313.612
            }} />
          </div>
        </div>
        <div style={{ height: 520 }} />


        {/* address */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          fontFamily: 'Cambria',
          paddingBottom: 50, position: 'absolute',
          zIndex: 10, width: '100%', backgroundColor: 'white'
        }}>
          <div style={{
            flex: 1,
            display: 'flex',
            paddingLeft: 50,
            justifyContent: 'flex-start',
            flexDirection: 'row', alignItems: 'flex-start',
            fontSize: 20, fontFamily: 'got'
          }}>
            <div>
              <span>Address:</span><br />
              <span>Hom2 Phonsavanh Village, Sisttanak, Vientiane Cap.</span>
            </div>
            <a target="_blank" href='https://www.google.com/maps/place/Manee+Residence/@17.9515511,102.6231016,17z/data=!4m5!3m4!1s0x312467a8750e21f9:0x67e10f8a5de5ac96!8m2!3d17.9510816!4d102.6243461'
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <button style={{
                fontFamily: 'got', width: 220,
                borderRadius: 3, backgroundColor: 'transparent',
                color: '#000', marginTop: 20,
                border: '1px solid red',
                marginLeft: 40, paddingTop: 8, paddingBottom: 8
              }} ref={register}>
                GOOGLE MAP
              </button>
            </a>
          </div>

        </div>
        <div style={{ height: 100 }} />

        {/* Register */}
        <div style={{
          backgroundColor: '#003044',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start', padding: 30, paddingLeft: 50,
          color: 'white', position: 'absolute', zIndex: 10, width: '100%',
          paddingBottom: 50, height: 400
        }} >
          <span style={{
            fontSize: 48, fontFamily: 'bas'
          }}>REGISTER</span>
          <span style={{ fontSize: 22, fontFamily: 'got' }}>FOR SITE VISIT APPOINTMENT</span><br />
          <form onSubmit={sendEmail}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', fontFamily: 'got', width: '100%'
            }}>
              <span>NAME:</span>
              <input style={{ height: 45, outline: 'none', paddingLeft: 10, width: 350, marginLeft: 50 }} type="text" name="name"
                onChange={(e) => setOne(e.target.value)} value={one} />
              <span style={{ marginLeft: 40 }}>SURNAME:</span>
              <input style={{ height: 45, outline: 'none', paddingLeft: 10, width: 350, marginLeft: 40 }} type="text" name="surname"
                onChange={(e) => setTwo(e.target.value)} value={two} />
            </div><br /><br />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', fontFamily: 'got'
            }}>
              <span>MOBILE:</span>
              <input style={{ height: 45, outline: 'none', paddingLeft: 10, width: 350, marginLeft: 35 }} name="mobile"
                onChange={(e) => setThree(e.target.value)} value={three} />
              <span style={{ marginLeft: 40 }}>EMAIL:</span>
              <input style={{ height: 45, outline: 'none', paddingLeft: 10, width: 350, marginLeft: 72 }} type="email" name="email"
                onChange={(e) => setFour(e.target.value)} value={four} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <button style={{
                fontFamily: 'got',
                borderRadius: 3, backgroundColor: 'transparent',
                height: 45, color: '#fff', marginTop: 20,
                paddingLeft: 30, paddingRight: 30, border: '1px solid #fff',
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
              }}>
                <span>SUBMIT</span>
              </button>
              <span style={{
                marginLeft: 50, marginTop: 20, fontFamily: 'got'
              }}>we will contact you immediately after receiving your form.</span>
            </div>
          </form>
        </div>
        <div style={{ height: 400 }} />
        <div style={{
          position: 'absolute', zIndex: 5, width: '100%',
          backgroundColor: 'black', height: 80, paddingLeft: 50,
          display: 'flex', alignItems: 'center', color: 'white'
        }}>
          <span style={{ fontSize: 30 }}>
            Bounmani Real Estate Co., LTD
          </span>
        </div>
        <div style={{ height: 80 }} />
        {/* footer */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute', zIndex: 5, width: '100%'
        }}>
          <div style={{
            flex: 1, display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontFamily: 'got', paddingLeft: 50,
            backgroundColor: '#000', color: 'white'
          }}>

            <span style={{ fontSize: 20 }}>HEAD OFFICE</span><br />
            <span style={{ fontSize: 20 }}>Hom2 Phonsavanh Village, Sisattanak, Vientiane Cap.</span><br />
            <span style={{ fontSize: 20 }}>Call: 020 5999 1555</span><br />
            <span style={{ fontSize: 20 }}>Email: ManeeResidenceCondo@gmail.com</span><br />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{
                width: 40, height: 40, borderRadius: 3,
                backgroundColor: 'white',
                alignItems: 'center', display: 'flex',
                justifyContent: 'center', alignContent: 'center'
              }}>
                <BsWhatsapp size={24} color="black" />
              </div>
              <img src={line} style={{ width: 50, height: 40, marginLeft: 20, borderRadius: 3 }} />
              <span style={{ marginLeft: 20, fontSize: 20 }}> 020 5999 1555</span>
              <BsFacebook style={{ marginLeft: 20 }} />
              <span style={{ marginLeft: 20, fontSize: 20 }}>Manee Residence</span>
              <img src={isologo} style={{ width: 120, height: 100, marginLeft: 20, paddingBottom: 30}} />
            </div>
          </div>
          <div style={{
            flex: 1, backgroundColor: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 100
          }}>
            <img src={footer} />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div>
          {
            showToast === true && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%', position: 'fixed',
                alignItems: 'center',
                paddingTop: 80, borderRadius: 5, zIndex: 20
              }}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
                  paddingTop: 10, paddingBottom: 10
                }}>
                  <Toast.Header>
                    <strong className="me-auto" style={{ color: 'red', fontFamily: 'bas' }}> Please complete information !</strong>
                  </Toast.Header>
                </Toast>
              </div>
            )
          }
          {
            showToast1 === true && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%', position: 'fixed',
                alignItems: 'center',
                paddingTop: 80, borderRadius: 5, zIndex: 20
              }}>
                <Toast onClose={() => setShowToast1(false)} show={showToast1} delay={3000} autohide style={{
                  paddingTop: 10, paddingBottom: 10
                }}>
                  <Toast.Header>
                    <strong className="me-auto" style={{ color: '#8A744B', fontFamily: 'bas' }}> Successful !</strong>
                  </Toast.Header>
                </Toast>
              </div>
            )
          }
          <div style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: 0, width: '100%',
            display: 'flex',
            flexDirection: 'row',
            height: 60, alignItems: 'center',
            zIndex: 20, justifyContent: 'center'
          }}>
            <div style={{
              flex: 1,
              paddingLeft: 5,
              width: '100%', textAlign: 'center'
            }}>
              <img
                src={image} style={{
                  width: 120, height: 50
                }}
                onClick={Ontop}
              />
            </div>


          </div>
          <div style={{ height: 60 }} />
          {/* manee residence */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            paddingTop: 30, paddingBottom: 30,
            backgroundColor: '#003044', justifyContent: 'center'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'noto',
              fontSize: 12,
              justifyContent: 'center',
              color: 'white', alignItems: 'center'
            }}>
              <span>ມະນີ ຣີຊີເດັນ ຄອນໂດໃຈກາງເມືອງ</span>
              <span>ມິຕິໃໝ່ຂອງການຢູ່ອາໃສ ຄົບຖ້ວນດ້ວຍ</span>
              <span>ສິ່ງອຳນວຍຄວາມສະດວກ</span>
              <span>ເປັນເຈົ້າຂອງກຳມະສິດຖາວອນ</span>
              <span>ຄຸ້ມຄ່າແກ່ການລົງທຶນ</span>
            </div>
            <div style={{
              display: 'flex', flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center', fontFamily: 'bas',
              color: 'white',
              paddingTop: 20
            }}>
              <span style={{ fontSize: 16 }}>THE URBAN LIFE STYLE</span>
              <span style={{ fontSize: 16, marginTop: -5 }}>CONDOMINIUM</span>
            </div>

          </div>

          {/* condoimage */}
          <div style={{
            backgroundColor: '#003044'
          }}>
            <img
              src={leftImage} style={{
                width: '100%'
              }}
            />
            <div style={{
              paddingTop: 10, paddingBottom: 10,
              display: 'flex', flexDirection: 'row',
              justifyContent: 'space-between', flex: 1
            }}>
              <div style={{
                display: 'flex',
                flex: 1, flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end', paddingRight: 15
              }}>
                {/* <span style={{ color: 'white', fontFamily: 'noto', marginRight: 15 }}>ລາຄາເລີ່ມຕົ້ນ</span>
                <span style={{ color: '#B89B6A', fontFamily: 'bas' }}>START FROM</span> */}
                <span style={{ color: 'white', fontFamily: 'noto', marginRight: 15 }}></span>
                <span style={{ color: '#B89B6A', fontFamily: 'bas' }}></span>
              </div>
              <div style={{
                display: 'flex', flexDirection: 'row',
                justifyContent: 'flex-start',
                flex: 1, alignItems: 'center'
              }}>
                {/* <span style={{ color: '#B89B6A', fontFamily: 'asia', fontSize: 40 }}>77,900$</span> */}
                <span style={{ color: '#B89B6A', fontFamily: 'asia', fontSize: 40 }}></span>
              </div>
            </div>
          </div>



          {/* the urban life style */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#C2B193',
            paddingTop: 20, paddingBottom: 20, color: 'white',
            paddingLeft: 50, paddingRight: 50
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 13, fontFamily: 'got',
              justifyContent: 'flex-start',
              paddingBottom: 10
            }}>
              <span style={{ marginLeft: 1 }}>M</span>
              <span style={{ marginLeft: 1 }}>A</span>
              <span style={{ marginLeft: 1 }}>N</span>
              <span style={{ marginLeft: 1 }}>E</span>
              <span style={{ marginLeft: 1 }}>E</span>
              <span style={{ marginLeft: 10 }}>R</span>
              <span style={{ marginLeft: 1 }}>E</span>
              <span style={{ marginLeft: 1 }}>S</span>
              <span style={{ marginLeft: 1 }}>I</span>
              <span style={{ marginLeft: 1 }}>D</span>
              <span style={{ marginLeft: 1 }}>E</span>
              <span style={{ marginLeft: 1 }}>N</span>
              <span style={{ marginLeft: 1 }}>C</span>
              <span style={{ marginLeft: 1 }}>E</span>

              <span style={{ marginLeft: 10 }}>C</span>
              <span style={{ marginLeft: 1 }}>O</span>
              <span style={{ marginLeft: 1 }}>N</span>
              <span style={{ marginLeft: 1 }}>D</span>
              <span style={{ marginLeft: 1 }}>O</span>
              <span style={{ marginLeft: 1 }}>M</span>
              <span style={{ marginLeft: 1 }}>I</span>
              <span style={{ marginLeft: 1 }}>N</span>
              <span style={{ marginLeft: 1 }}>I</span>
              <span style={{ marginLeft: 1 }}>U</span>
              <span style={{ marginLeft: 1 }}>M</span>

            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'got'
              }}>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  fontSize: 12
                }}>
                  <span>TENURE:</span>
                  <div style={{ height: 5 }} />
                  <span>TOTAL AREA:</span>
                  <div style={{ height: 5 }} />
                  <span>FLOORS:</span>
                  <div style={{ height: 5 }} />
                  <span>TOTAL UNIT:</span>
                  <div style={{ height: 5 }} />
                  <span>LIFTS:</span>
                  <div style={{ height: 5 }} />
                  <span>CAR PARKING:</span>
                </div>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end', fontSize: 12
                }}>
                  <span>FREEHOLD</span>
                  <div style={{ height: 5 }} />
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <span>2,808 sqm</span>
                    <span style={{ fontSize: 10, marginTop: -10 }}>2</span>
                  </div>
                  <div style={{ height: 5 }} />
                  <span>9 floors</span>
                  <div style={{ height: 5 }} />
                  <span>59 units</span>
                  <div style={{ height: 5 }} />
                  <span>4 units</span>
                  <div style={{ height: 5 }} />
                  <span>64 lots</span>
                </div>
              </div>
              <div style={{ width: 50 }}>

              </div>
            </div>

          </div>

           {/* 360 */}
           <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#C2B193',
            paddingTop: 20, paddingBottom: 20, color: 'white',
            paddingLeft: 50, paddingRight: 50
          }}>
            
              <div style={{ width: 50 }}>

              </div>

          </div>


          {/* Project COncept */}
          <div >
          <a href="https://360.maneeresidence.com/">
          <img src={back360mb} style={{
              height: 'auto',
              width: '100%'
            }} />
          </a>
            
          </div>

          {/* free in rooms */}
          <div style={{
            paddingLeft: 35, paddingRight: 35,
            paddingBottom: 20, backgroundColor: 'white'
          }}>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'noto', fontSize: 14,
              paddingTop: 20
            }}>
              <span>ແນວຄວາມຄິດໂຄງການ</span>
              <span style={{ marginTop: -5 }}>ມະນີ ຣີຊີເດັນ ຄອນໂດ</span>

            </div>
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <span style={{ color: '#B89B6A', fontSize: 16, fontFamily: 'noto' }}>ຄອນໂດມິຕິໃໝ່ຂອງການຢູ່ອາໃສ</span>
            </div>

            <div style={{
              color: '#000', fontFamily: 'noto',
              display: 'flex', flexDirection: 'column',
              fontSize: 12
            }}>
              <span>ມະນີ ຣີຊິເດັ້ນ ສ້າງຂື້ນພາຍໃຕ້ແນວຄິດທັນສະໄໝ ຫລູຫລາ

                ແລະ ສະດວກສະບາຍແກ່ຜູ້ພັກອາໃສໃນໃຈກາງເມືອງໂດຍນຳໃຊ້

                ເທັກໂນໂລຢີສະໄໝໃໝ່ ເຊີ່ງຄຳນຶງເຖິງສິ່ງແວດລ້ອມເປັນສຳຄັນ,

                ເຮົາໃສ່ໃຈທຸກລາຍລະອຽດບໍ່ວ່າການຈັດສັນພື້ນທີ່ໃຊ້ສອຍໃຫ້ມີການ

                ແບ່ງຫ້ອງເພື່ອຈັດວາງເຄື່ອງໃຊ້ຕ່າງໆຢ່າງລົງຕົວ ເນັ້ນສ້າງຄວາມ

                ສະດວກສະບາຍໃນການຢູ່ອາໃສໄລຍະຍາວ ສອດຄ່ອງກັບການໃຊ້

                ຊີວິດປະຈຳວັນຂອງຄົນໃນສັງຄົມເມືອງ.

                ພາຍໃນໂຄງການຍັງມີສິ່ງອຳນວຍຄວາມສະດວກເຊັ່ນ: ສະລອຍນໍ້າ

                ລະບົບນາໂນເຕັກ, ແຜງໄຟຟ້າໂຊລ່າເຊວ,ບ່ອນຈອດລົດສ່ວນຕົວ,

                ຫ້ອງຊ້ອມຕີກ໋ອບ, ຫ້ອງອອກກຳລັງກາຍຂະໜາດໃຫຍ່,

                ຮ້ານອາຫານເພື່ອສຸຂະພາບ, ບໍລິການຊັກລິດ ແລະ ຮ້ານສະດວກຊື້.

                ຕົວອາຄານສ້າງດ້ວຍມາດຕະຖານທາງວິສະວະກຳລະດັບສູງ

                ແລະ ຜ່ານການຮັບຮອງຕາມມາດຕະຖານ ISO 9001.</span>
            </div>
          </div>
          <div style={{
            flex: 1,
            backgroundColor: '#C2B193',
          }}>
            <Carousel indicators={false}>
              <Carousel.Item interval={2000}>
                <img
                  src={famo1}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo2}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo3}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo4}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo5}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo6}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo7}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <img
                  src={famo8}
                  style={{
                    height: 'auto',
                    width: '100%',

                  }}
                />
              </Carousel.Item>
            </Carousel>
          </div>


          {/* FACILITEIS */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 35, paddingRight: 35,
            paddingBottom: 20, paddingTop: 20
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20 }}>F</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>A</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>C</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>I</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>L</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>I</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>T</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>I</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>E</span>
              <span style={{ color: '#00', fontFamily: 'bas', fontSize: 20, marginLeft: 2 }}>S</span>
            </div>
            <img src={mobileFaci} style={{
              width: '100%', height: 'auto'
            }} />

          </div>


          {/* gallary */}
          <div style={{
            backgroundColor: '#002F43',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: 10, paddingBottom: 20
          }} >

            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              color: 'white', justifyContent: 'space-between',
              paddingTop: 30
            }}>

              <div style={{
                display: 'flex', flex: 1,
                alignItems: 'center',
                fontSize: 12, color: '#fff',
                fontFamily: 'got', justifyContent: 'space-between',
                paddingLeft: 50, paddingRight: 50
              }}>
                <div style={{
                  borderBottom: category === 'interior' ? '2px solid white' : '',
                  color: category === 'interior' ? '#BFA071' : 'white', cursor: 'pointer'
                }}>
                  <span onClick={() => setCategory('interior')} style={{}}>INTERIOR</span>
                </div>
                <div style={{
                  borderBottom: category === 'exterior' ? '2px solid white' : '',
                  color: category === 'exterior' ? '#BFA071' : 'white', cursor: 'pointer'
                }}>
                  <span onClick={() => setCategory('exterior')}>EXTERIOR</span>
                </div>
                <div style={{
                  borderBottom: category === 'vdo' ? '2px solid white' : '',
                  color: category === 'vdo' ? '#BFA071' : 'white', cursor: 'pointer'
                }}>
                  <span onClick={() => setCategory('vdo')}>VDO</span>
                </div>
              </div>
            </div>
          </div>

          {/* fullscreen Facilities */}
          <div style={{
            backgroundColor: '#002F43',
          }}>
            <div style={{
              paddingLeft: 10, paddingRight: 10
            }}>
              {
                category === 'vdo' && (
                  <video controls style={{ width: '100%', height: 'auto' }}>
                    <source src={video} />
                  </video>
                )
              }
              {
                category === 'exterior' && (
                  <img src={ex1} style={{
                    width: '100%',
                    height: 'auto', borderRadius: 3
                  }} />
                )
              }
              {
                category === 'interior' && (
                  <img src={in1} style={{
                    width: '100%',
                    height: 'auto', borderRadius: 3
                  }} />
                )
              }
              <div style={{
                paddingTop: 20, paddingBottom: 20,
                display: 'flex',
                justifyContent: 'center'
              }}>
                {
                  category === 'interior' && (
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: '#000',
                      height: 20, color: 'white',
                      paddingLeft: 30, paddingRight: 30, marginTop: -50,
                      fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center',
                      border: 'none'
                    }} ref={facilities} onClick={() => setFullscreen('interior')}>

                      FULL SCREEN
                    </button>
                  )
                }
                {
                  category === 'exterior' && (
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: '#000',
                      height: 20, color: 'white',
                      paddingLeft: 30, paddingRight: 30, marginTop: -50,
                      fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center',
                      border: 'none'
                    }} ref={facilities} onClick={() => setFullscreen('exterior')}>

                      FULL SCREEN
                    </button>
                  )
                }


              </div>
            </div>
            {/* fullscreen slide image */}
            {
              fullscreen === 'interior' && (
                <div style={{
                  width: '100%', height: '100%',
                  position: 'fixed', zIndex: 20, top: 0,
                  backgroundColor: 'black', left: 0, display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingTop: 20, paddingBottom: 20,
                    color: 'white', position: 'fixed',
                    top: 20, zIndex: 22, display: 'flex',
                    justifyContent: 'flex-start', width: '100%',
                    paddingLeft: 10
                  }}>
                    <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
                  </div>
                  <Carousel indicators={false}>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in1}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in2}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in3}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in4}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in5}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in6}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in7}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in8}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in9}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in10}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in11}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in12}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in13}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in14}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in15}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in16}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={in17}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>

                  </Carousel>
                </div>
              )
            }
            {/* exteriori fullscreen */}
            {
              fullscreen === 'exterior' && (
                <div style={{
                  width: '100%', height: '100%',
                  position: 'fixed', zIndex: 20, top: 0,
                  backgroundColor: 'black', left: 0, display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingTop: 20, paddingBottom: 20, color: 'white'
                    , position: 'fixed',
                    top: 20, zIndex: 22, display: 'flex',
                    justifyContent: 'flex-start', width: '100%',
                    paddingLeft: 10
                  }}>
                    <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
                  </div>
                  <Carousel indicators={false}>
                    <Carousel.Item interval={2000}>
                      <img
                        src={ex1}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <img
                        src={ex2}
                        style={{
                          height: 'auto',
                          width: '100%',

                        }}
                      />
                    </Carousel.Item>


                  </Carousel>
                </div>
              )
            }
            {
              fullscreen === 'vdo' && (
                <div style={{
                  width: '100%', height: '100%',
                  position: 'fixed', zIndex: 20, top: 0,
                  backgroundColor: 'black', left: 0, display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <div style={{
                    paddingTop: 20, paddingBottom: 20,
                    display: 'flex', position: 'fixed',
                    top: 20, zIndex: 22, display: 'flex',
                    justifyContent: 'flex-start', width: '100%',
                    paddingLeft: 10
                  }}>
                    <BiArrowBack size={24} color="white" onClick={() => setFullscreen('')} />
                  </div>
                  <video controls style={{ width: '100%', height: 300 }}>
                    <source src={video} />
                  </video>
                </div>
              )
            }

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'white',
              flexDirection: 'row',
              fontFamily: 'bas', fontSize: 18,
              alignItems: 'center',
              marginTop: -20,
            }}>

              <span style={{}}>F</span>
              <span style={{ marginLeft: 2 }}>R</span>
              <span style={{ marginLeft: 2 }}>E</span>
              <span style={{ marginLeft: 2 }}>E</span>
              <span style={{ marginLeft: 10 }}>I</span>
              <span style={{ marginLeft: 2 }}>N</span>
              <span style={{ marginLeft: 10 }}>R</span>
              <span style={{ marginLeft: 2 }}>O</span>
              <span style={{ marginLeft: 2 }}>O</span>
              <span style={{ marginLeft: 2 }}>M</span>
              <span style={{ marginLeft: 10 }}>D</span>
              <span style={{ marginLeft: 2 }}>E</span>
              <span style={{ marginLeft: 2 }}>C</span>
              <span style={{ marginLeft: 2 }}>O</span>
              <span style={{ marginLeft: 2 }}>R</span>
              <span style={{ marginLeft: 2 }}>A</span>
              <span style={{ marginLeft: 2 }}>T</span>
              <span style={{ marginLeft: 2 }}>I</span>
              <span style={{ marginLeft: 2 }}>O</span>
              <span style={{ marginLeft: 2 }}>N</span>

            </div>
            {/* free1 free2 free3 */}
            <div style={{
              backgroundColor: '#002F43',
              paddingLeft: 0, paddingRight: 10
            }}>
              <Carousel indicators={false}>
                <Carousel.Item >
                  <div style={{
                    paddingLeft: 50, paddingRight: 50,
                    paddingTop: 20, paddingBottom: 20
                  }}>
                    <img
                      src={Free1}
                      style={{
                        height: windowSize.innerWidth <= 360 ? 100 :
                          windowSize.innerWidth <= 460 ? 120 :
                            windowSize.innerWidth <= 560 ? 160 :
                              windowSize.innerWidth <= 760 ? 180 : 240,
                        width: '100%',

                      }}
                      className="d-block w-100"
                      alt="First slide"
                    />
                  </div>

                </Carousel.Item>
                <Carousel.Item>
                  <div style={{
                    paddingLeft: 50, paddingRight: 50,
                    paddingTop: 20, paddingBottom: 20
                  }}>
                    <img
                      src={Free2}
                      style={{
                        height: windowSize.innerWidth <= 360 ? 100 :
                          windowSize.innerWidth <= 460 ? 120 :
                            windowSize.innerWidth <= 560 ? 160 :
                              windowSize.innerWidth <= 760 ? 180 : 240,
                        width: '100%',

                      }}
                      className="d-block w-100"
                      alt="First slide"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{
                    paddingLeft: 50, paddingRight: 50,
                    paddingTop: 20, paddingBottom: 20
                  }}>
                    <img
                      src={Free3}
                      style={{
                        height: windowSize.innerWidth <= 360 ? 100 :
                          windowSize.innerWidth <= 460 ? 120 :
                            windowSize.innerWidth <= 560 ? 160 :
                              windowSize.innerWidth <= 760 ? 180 : 240,
                        width: '100%',

                      }}
                      className="d-block w-100"
                      alt="First slide"
                    />
                  </div>
                </Carousel.Item>

              </Carousel>
            </div>

          </div>






          {/* GUARANTEE */}
          <div style={{
            backgroundColor: '#C2B193',
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            justifyContent: 'center',
            color: 'white', flexDirection: 'row',
            fontFamily: 'bas', fontSize: 18,
          }}>
            <span style={{}}>F</span>
            <span style={{ marginLeft: 2 }}>R</span>
            <span style={{ marginLeft: 2 }}>E</span>
            <span style={{ marginLeft: 2 }}>E</span>
            <span style={{ marginLeft: 10 }}>I</span>
            <span style={{ marginLeft: 2 }}>N</span>
            <span style={{ marginLeft: 10 }}>R</span>
            <span style={{ marginLeft: 2 }}>O</span>
            <span style={{ marginLeft: 2 }}>O</span>
            <span style={{ marginLeft: 2 }}>M</span>
            <span style={{ marginLeft: 10 }}>D</span>
            <span style={{ marginLeft: 2 }}>E</span>
            <span style={{ marginLeft: 2 }}>C</span>
            <span style={{ marginLeft: 2 }}>O</span>
            <span style={{ marginLeft: 2 }}>R</span>
            <span style={{ marginLeft: 2 }}>A</span>
            <span style={{ marginLeft: 2 }}>T</span>
            <span style={{ marginLeft: 2 }}>I</span>
            <span style={{ marginLeft: 2 }}>O</span>
            <span style={{ marginLeft: 2 }}>N</span>

          </div>
          <div style={{
            display: 'flex', paddingLeft: 50, paddingRight: 50,
            fontFamily: 'asia', fontSize: 18,
            fontWeight: 'bold', backgroundColor: '#C2B193',
            flexDirection: 'column',
            paddingTop: 20, paddingBottom: 20
          }}>
            <img src={gunmobile} style={{ width: '100%' }} />
          </div>




          {/* Download Room Plan */}

          <div style={{
            backgroundColor: '#fff',
            display: 'flex', flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#000',
            paddingLeft: 20, paddingRight: 20, paddingTop: 10
          }} >

            {/* area and floor */}
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
              <div style={{
                display: 'flex', flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingBottom: 10
              }}>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>R</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>O</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>O</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>M</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 12 }}>F</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>L</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>O</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>O</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>R</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 12 }}>P</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>L</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>A</span>
                <span style={{ fontSize: 20, fontFamily: 'bas', color: '#C2B193', marginLeft: 2 }}>N</span>
              </div>

              <span style={{ fontSize: 14, fontFamily: 'got' }}>Room {type1}({room}).</span>
              {room === 'B301' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
              {room === 'B302' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
              {room === 'B303' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 61.45 sqm</span>)}
              {room === 'B304' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
              {room === 'B305' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
              {room === 'B402' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B502' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B602' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B702' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B802' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B806' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
              {room === 'B807' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
              {room === 'B902' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 47.89 sqm</span>)}
              {room === 'B904' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 41.05 sqm</span>)}
              {room === 'B905' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 50.63 sqm</span>)}
              {/* {room === 'B303' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 61.45 sqm</span>)} */}
              {room === 'B403' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
              {room === 'B408' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
              {room === 'B503' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
              {room === 'B508' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
              {room === 'B603' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
              {room === 'B608' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
              {room === 'B703' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.35 sqm</span>)}
              {room === 'B708' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.04 sqm</span>)}
              {room === 'B805' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 62.91 sqm</span>)}
              {room === 'B306' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
              {room === 'B307' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.14 sqm</span>)}
              {room === 'B308' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 80.63 sqm</span>)}
              {room === 'B808' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
              {room === 'B809' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.14 sqm</span>)}
              {room === 'B906' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 81.28 sqm</span>)}
              {room === 'B907' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 78.14 sqm</span>)}
              {room === 'B444' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B555' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B666' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B777' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B888' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B999' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 198.13 sqm</span>)}
              {room === 'B404' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B405' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
              {room === 'B406' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
              {room === 'B407' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B803' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 168.59 sqm</span>)}
              {room === 'B804' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 156.34 sqm</span>)}
              {room === 'B810' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 156.34 sqm</span>)}
              {room === 'B811' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 168.54 sqm</span>)}
              {room === 'B504' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B505' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 162.64 sqm</span>)}
              {room === 'B506' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
              {room === 'B507' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B604' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B605' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
              {room === 'B606' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
              {room === 'B607' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B704' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}
              {room === 'B705' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 126.64 sqm</span>)}
              {room === 'B706' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 120.94 sqm</span>)}
              {room === 'B707' && (<span style={{ fontSize: 14, fontFamily: 'got' }}>Area: 117.29 sqm</span>)}




              {room === 'B301' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}
              {room === 'B302' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}
              {/* {room === 'B303' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
      <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
      <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
    </div>)} */}
              {room === 'B304' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}
              {room === 'B305' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}
              {room === 'B402' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B502' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B602' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B702' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B802' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B806' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)} {room === 'B807' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)} {room === 'B902' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)} {room === 'B904' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B905' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B303' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}{room === 'B403' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B408' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B503' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B508' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B603' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B608' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B703' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B708' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B805' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B306' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}{room === 'B307' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}{room === 'B308' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 3&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>rd</span><br />
              </div>)}{room === 'B808' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B809' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B906' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B907' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B444' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B555' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B666' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B777' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B888' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B999' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 9&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B404' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B405' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B406' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B407' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 4&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B803' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B804' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B810' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B811' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 8&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}
              {room === 'B504' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B505' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B506' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B507' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 5&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B604' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B605' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B606' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B607' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 6&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B704' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B705' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B706' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}{room === 'B707' && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 14, fontFamily: 'got' }}>Level: 7&nbsp;&nbsp;&nbsp;&nbsp;floor</span><br />
                <span style={{ fontSize: 14, fontFamily: 'got', marginTop: -4, marginLeft: -50 }}>th</span><br />
              </div>)}

              <div style={{ height: 20 }} />


              <select style={{
                border: '1px solid #000',
                paddingTop: 10, paddingBottom: 10,
                width: '100%',
                backgroundColor: 'transparent',
                outline: 'none', fontFamily: 'got',
                color: '#000',
                backgroundColor: '#fff',
                borderLeft: 'none',
                borderRight: 'none', fontSize: 14,
              }}
                onChange={(e) => setType1(e.target.value)}
                value={type1}
              >
                <option value={'type: STANDARD 1 BEDROOM'} style={{ height: 50 }} >TYPE: STANDARD 1 BEDROOM</option>
                <option value={'type: PREMIER 1 BEDROOM'} style={{ height: 50 }} >TYPE: PREMIER 1 BEDROOM</option>
                <option value={'type: STANDARD 2 BEDROOMS'} >TYPE: STANDARD 2 BEDROOMS</option>
                <option value={'type: PREMIER 2 BEDROOMS'} >TYPE: PREMIER 2 BEDROOMS</option>
                <option value={'type: EXECUTIVE SUIT 3 BEDROOMS'} >TYPE: EXECUTIVE SUIT 3 BEDROOMS</option>
                <option value={'type: DUPLEX 3 BEDROOMS'} >TYPE: DUPLEX 3 BEDROOMS</option>
              </select>
              <br />
              <select style={{
                border: '1px solid #000',
                paddingTop: 10, paddingBottom: 10,
                width: '100%',
                backgroundColor: 'transparent',
                outline: 'none', fontFamily: 'got',
                color: '#000',
                backgroundColor: '#fff',
                borderLeft: 'none', fontSize: 14,
                borderRight: 'none', borderTop: 'none', marginTop: -20
              }}
                onChange={(e) => setRoom(e.target.value)}
                value={room}
              >
                {
                  type1 === 'type: STANDARD 1 BEDROOM' && (
                    <>
                      <option value={'B301'} style={{ paddingTop: 10 }}>ROOM NO: B301</option>
                      <option value={'B302'} style={{ paddingTop: 10 }}>ROOM NO: B302</option>
                      <option value={'B303'} style={{ paddingTop: 10 }}>ROOM NO: B303</option>
                      <option value={'B304'} style={{ paddingTop: 10 }}>ROOM NO: B304</option>
                      <option value={'B305'} style={{ paddingTop: 10 }}>ROOM NO: B305</option>
                      <option value={'B402'} style={{ paddingTop: 10 }}>ROOM NO: B402</option>
                      <option value={'B502'} style={{ paddingTop: 10 }}>ROOM NO: B502</option>
                      <option value={'B602'} style={{ paddingTop: 10 }}>ROOM NO: B602</option>
                      <option value={'B702'} style={{ paddingTop: 10 }}>ROOM NO: B702</option>
                      <option value={'B802'} style={{ paddingTop: 10 }}>ROOM NO: B802</option>
                      <option value={'B806'} style={{ paddingTop: 10 }}>ROOM NO: B806</option>
                      <option value={'B807'} style={{ paddingTop: 10 }}>ROOM NO: B807</option>
                      <option value={'B902'} style={{ paddingTop: 10 }}>ROOM NO: B902</option>
                      <option value={'B904'} style={{ paddingTop: 10 }}>ROOM NO: B904</option>
                      <option value={'B905'} style={{ paddingTop: 10 }}>ROOM NO: B905</option>
                    </>
                  )
                }
                {
                  type1 === 'type: PREMIER 1 BEDROOM' && (
                    <>
                      <option value={'B303'} style={{ paddingTop: 10 }}>ROOM NO: B303</option>
                      <option value={'B403'} style={{ paddingTop: 10 }}>ROOM NO: B403</option>
                      <option value={'B408'} style={{ paddingTop: 10 }}>ROOM NO: B408</option>
                      <option value={'B503'} style={{ paddingTop: 10 }}>ROOM NO: B503</option>
                      <option value={'B508'} style={{ paddingTop: 10 }}>ROOM NO: B508</option>
                      <option value={'B603'} style={{ paddingTop: 10 }}>ROOM NO: B603</option>
                      <option value={'B608'} style={{ paddingTop: 10 }}>ROOM NO: B608</option>
                      <option value={'B703'} style={{ paddingTop: 10 }}>ROOM NO: B703</option>
                      <option value={'B708'} style={{ paddingTop: 10 }}>ROOM NO: B708</option>
                      <option value={'B805'} style={{ paddingTop: 10 }}>ROOM NO: B805</option>
                    </>
                  )
                }
                {
                  type1 === 'type: STANDARD 2 BEDROOMS' && (
                    <>
                      <option value={'B306'} style={{ paddingTop: 10 }}>ROOM NO: B306</option>
                      <option value={'B307'} style={{ paddingTop: 10 }}>ROOM NO: B307</option>
                      <option value={'B308'} style={{ paddingTop: 10 }}>ROOM NO: B308</option>
                      <option value={'B808'} style={{ paddingTop: 10 }}>ROOM NO: B808</option>
                      <option value={'B809'} style={{ paddingTop: 10 }}>ROOM NO: B809</option>
                      <option value={'B906'} style={{ paddingTop: 10 }}>ROOM NO: B906</option>
                      <option value={'B907'} style={{ paddingTop: 10 }}>ROOM NO: B907</option>
                    </>
                  )
                }
                {
                  type1 === 'type: PREMIER 2 BEDROOMS' && (
                    <>
                      <option value={'B404'} style={{ paddingTop: 10 }}>ROOM NO: B404</option>
                      <option value={'B405'} style={{ paddingTop: 10 }}>ROOM NO: B405</option>
                      <option value={'B406'} style={{ paddingTop: 10 }}>ROOM NO: B406</option>
                      <option value={'B407'} style={{ paddingTop: 10 }}>ROOM NO: B407</option>
                      <option value={'B504'} style={{ paddingTop: 10 }}>ROOM NO: B504</option>
                      <option value={'B505'} style={{ paddingTop: 10 }}>ROOM NO: B505</option>
                      <option value={'B506'} style={{ paddingTop: 10 }}>ROOM NO: B506</option>
                      <option value={'B507'} style={{ paddingTop: 10 }}>ROOM NO: B507</option>
                      <option value={'B604'} style={{ paddingTop: 10 }}>ROOM NO: B604</option>
                      <option value={'B605'} style={{ paddingTop: 10 }}>ROOM NO: B605</option>
                      <option value={'B606'} style={{ paddingTop: 10 }}>ROOM NO: B606</option>
                      <option value={'B607'} style={{ paddingTop: 10 }}>ROOM NO: B607</option>
                      <option value={'B704'} style={{ paddingTop: 10 }}>ROOM NO: B704</option>
                      <option value={'B705'} style={{ paddingTop: 10 }}>ROOM NO: B705</option>
                      <option value={'B706'} style={{ paddingTop: 10 }}>ROOM NO: B706</option>
                      <option value={'B707'} style={{ paddingTop: 10 }}>ROOM NO: B707</option>
                    </>
                  )
                }
                {
                  type1 === 'type: EXECUTIVE SUIT 3 BEDROOMS' && (
                    <>
                      <option value={'B444'} style={{ paddingTop: 10 }}>ROOM NO: B444</option>
                      <option value={'B555'} style={{ paddingTop: 10 }}>ROOM NO: B555</option>
                      <option value={'B666'} style={{ paddingTop: 10 }}>ROOM NO: B666</option>
                      <option value={'B777'} style={{ paddingTop: 10 }}>ROOM NO: B777</option>
                      <option value={'B888'} style={{ paddingTop: 10 }}>ROOM NO: B888</option>
                      <option value={'B999'} style={{ paddingTop: 10 }}>ROOM NO: B999</option>
                    </>
                  )
                }
                {
                  type1 === 'type: DUPLEX 3 BEDROOMS' && (
                    <>
                      <option value={'B803'} style={{ paddingTop: 10 }}>ROOM NO: B803</option>
                      <option value={'B804'} style={{ paddingTop: 10 }}>ROOM NO: B804</option>
                      <option value={'B810'} style={{ paddingTop: 10 }}>ROOM NO: B810</option>
                      <option value={'B811'} style={{ paddingTop: 10 }}>ROOM NO: B811</option>
                    </>
                  )
                }

              </select>
              {/* <br />
              <span style={{ fontFamily: 'got', fontSize: 16 }} >Disclaimer: Items are not finalised and may be changed without notice..</span> */}
            </div>

            {/* floor plan image */}
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {/* STANDARD 1 BEDROOM */}
              {
                room === 'B301' && (
                  <>
                    <img src={B301} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B301')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B302' && (
                  <>
                    <img src={B302} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B302')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B304' && (
                  <>
                    <img src={B304} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B304')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B305' && (
                  <>
                    <img src={B305} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B305')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B402' && (
                  <>
                    <img src={B402} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B402')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B502' && (
                  <>
                    <img src={B502} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B502')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B809' && (
                  <>
                    <img src={B809} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B809')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B602' && (
                  <>
                    <img src={B602} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B602')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B702' && (
                  <>
                    <img src={B702} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B702')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B802' && (
                  <>
                    <img src={B802} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B802')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B806' && (
                  <>
                    <img src={B806} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B806')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B807' && (
                  <>
                    <img src={B807} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B807')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B902' && (
                  <>
                    <img src={B902} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B902')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B904' && (
                  <>
                    <img src={B904} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B904')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B905' && (
                  <>
                    <img src={B905} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B905')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }

              {/* PREMIER 1 BEDROOM */}
              {
                room === 'B303' && (
                  <>
                    <img src={B303} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B303')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B403' && (
                  <>
                    <img src={B403} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B403')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B408' && (
                  <>
                    <img src={B408} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B408')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B503' && (
                  <>
                    <img src={B503} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B503')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B508' && (
                  <>
                    <img src={B508} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B508')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B603' && (
                  <>
                    <img src={B603} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B603')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B608' && (
                  <>
                    <img src={B608} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B608')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B703' && (
                  <>
                    <img src={B703} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B703')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B708' && (
                  <>
                    <img src={B708} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B708')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B805' && (
                  <>
                    <img src={B805} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B805')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }


              {/* STANDARD 2 BEDROOMS */}

              {
                room === 'B306' && (
                  <>
                    <img src={B306} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B306')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B307' && (
                  <>
                    <img src={B307} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B307')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B307' && (
                  <>
                    <img src={B307} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B307')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B808' && (
                  <>
                    <img src={B808} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B808')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B906' && (
                  <>
                    <img src={B906} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B906')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B907' && (
                  <>
                    <img src={B907} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B907')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {/* PREMIER 2 BEDROOMS */}
              {
                room === 'B404' && (
                  <>
                    <img src={B404} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B404')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B405' && (
                  <>
                    <img src={B405} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B405')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B406' && (
                  <>
                    <img src={B406} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B406')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B407' && (
                  <>
                    <img src={B407} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B407')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B504' && (
                  <>
                    <img src={B504} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: '#000', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B504')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B505' && (
                  <>
                    <img src={B505} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B505')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B506' && (
                  <>
                    <img src={B506} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B506')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B507' && (
                  <>
                    <img src={B507} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B507')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B604' && (
                  <>
                    <img src={B604} style={{
                     width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B604')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B605' && (
                  <>
                    <img src={B605} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B605')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B606' && (
                  <>
                    <img src={B606} style={{
                     width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B606')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B607' && (
                  <>
                    <img src={B607} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B607')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B704' && (
                  <>
                    <img src={B704} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B704')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B705' && (
                  <>
                    <img src={B705} style={{
                     width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B705')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B706' && (
                  <>
                    <img src={B706} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B706')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B707' && (
                  <>
                    <img src={B707} style={{
                     width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 45, color: 'black', marginTop: 20,
                      paddingLeft: 30, paddingRight: 30, border: '1px solid black',
                      cursor: 'pointer', marginTop: 40,
                      width: '100%', fontSize: 14, display: 'flex', alignItems: 'center',
                      justifyContent: 'center'
                    }} onClick={() => onButtonClick('B707')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }



              {/* EXECUTIVE SUIT 3 BEDROOMS */}
              {
                room === 'B444' && (
                  <>
                    <img src={B444} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B444')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B555' && (
                  <>
                    <img src={B555} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B555')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B666' && (
                  <>
                    <img src={B666} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B666')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B777' && (
                  <>
                    <img src={B777} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B777')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B888' && (
                  <>
                    <img src={B888} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B888')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B999' && (
                  <>
                    <img src={B999} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B999')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }

              {/* DUPLEX 3 BEDROOMS */}
              {
                room === 'B803' && (
                  <>
                    <img src={B803} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B803')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B804' && (
                  <>
                    <img src={B804} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B804')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B810' && (
                  <>
                    <img src={B810} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B810')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }
              {
                room === 'B811' && (
                  <>
                    <img src={B811} style={{
                      width: '100%', height: 'auto',
                      borderRadius: 3,
                      marginTop: 20
                    }}
                    />
                    <button style={{
                      fontFamily: 'got',
                      borderRadius: 3, backgroundColor: 'transparent',
                      height: 35, color: '#000', marginTop: 20,
                      paddingLeft: 10, paddingRight: 10, border: '1px solid #000',
                      cursor: 'pointer', marginTop: 10,
                      width: '100%', fontSize: 14
                    }} onClick={() => onButtonClick('B811')}>
                      DOWNLOAD ROOM FLOOR PLAN
                    </button>
                  </>
                )
              }



            </div>
          </div>


          {/* Google Maps */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 20
          }} ref={location}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193' }}>A</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>D</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>D</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>R</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>E</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>S</span>
              <span style={{ fontFamily: 'bas', fontSize: 20, color: '#C2B193', marginLeft: 2 }}>S</span>

            </div>
            <div style={{
              flex: 1, paddingLeft: 10, paddingRight: 10
            }}>
              <img src={mobilemap} style={{
                width: '100%',
                height: 'auto'
              }} />
            </div>

          </div>
          {/* address */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontFamily: 'got',
            paddingBottom: 20,
          }}>
            <div style={{
              flex: 1, display: 'flex',
              justifyContent: 'center',
              paddingLeft: 10, paddingRight: 10
            }}>
              <button style={{
                fontFamily: 'got',
                borderRadius: 3, backgroundColor: 'transparent',
                height: 25, color: '#000', marginTop: 20,
                border: '1px solid red',
                width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center'
              }}>
                <a target="_blank" href='https://www.google.com/maps/place/Manee+Residence/@17.9515511,102.6231016,17z/data=!4m5!3m4!1s0x312467a8750e21f9:0x67e10f8a5de5ac96!8m2!3d17.9510816!4d102.6243461'
                  style={{ color: 'black', textDecoration: 'none', fontSize: 12 }}
                > GOOGLE MAP</a>
              </button>
            </div>
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column', alignItems: 'flex-start',
              paddingLeft: 10, paddingRight: 10, fontSize: 13, paddingTop: 20
            }}>
              <span>Address:</span>
              <span>Hom2 Phonsavanh Village, Sisattanak, Vientiane Cap.</span>
            </div>

          </div>

          {/* Register */}

          <div style={{
            backgroundColor: '#003044',

            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: 'white', paddingLeft: 10,
            paddingRight: 10, paddingTop: 20, paddingBottom: 20
          }} ref={register}>
            <span style={{
              fontSize: 30, fontFamily: 'bas',
            }}>REGISTER</span>
            <span>FOR SITE VISIT APPOINTMENT</span>
            <form onSubmit={sendEmail} style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                fontFamily: 'got'
              }}>
                <div style={{ height: 20 }} />
                <span>NAME:</span>
                <input style={{ height: 30, outline: 'none', paddingLeft: 10, width: '100%', border: 'none' }}
                  type="text" name="name"
                  onChange={(e) => setOne(e.target.value)}
                  value={one}
                />
                <div style={{ height: 10 }} />
                <span>SURNAME:</span>
                <input style={{ height: 30, outline: 'none', paddingLeft: 10, border: 'none' }}
                  type="text" name="surname"
                  onChange={(e) => setTwo(e.target.value)}
                  value={two}
                />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%', fontFamily: 'got'
              }}>
                <div style={{ height: 10 }} />
                <span>MOBILE:</span>
                <input style={{ height: 30, outline: 'none', paddingLeft: 10, border: 'none', }}
                  type="text" name="text"
                  onChange={(e) => setThree(e.target.value)}
                  value={three}
                />
                <div style={{ height: 10 }} />
                <span>EMAIL:</span>
                <input style={{ height: 30, outline: 'none', paddingLeft: 10, border: 'none' }}
                  type="email" name="email"
                  onChange={(e) => setFour(e.target.value)}
                  value={four}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <button style={{
                  fontFamily: 'got',
                  borderRadius: 3, backgroundColor: 'transparent',
                  height: 35, color: '#fff', marginTop: 20,
                  paddingLeft: 30, paddingRight: 30, border: '1px solid #fff',
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  justifyContent: 'center',
                  width: 100
                }}>
                  <span>SUBMIT</span>
                </button>
                <span style={{
                  fontSize: 12, marginTop: 10
                }}>we will contact you immediately after receiving your form.</span>
              </div>
            </form>
          </div>

          {/* Footer */}

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <div style={{
              flex: 1, display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              fontFamily: 'got', padding: 10,
              backgroundColor: '#000', color: 'white',
              paddingTop: 30
            }}>
              <span style={{ fontSize: 14 }}>
                Bounmani Real Estate Co., LTD
              </span>
              <span style={{ fontSize: 14, marginTop: 10 }}>HEAD OFFICE:</span>
              <span style={{ fontSize: 12, marginTop: -5 }}>Hom2 Phonsavanh Village, Sisattanak, Vientiane Cap.</span>
              <div style={{ height: 15 }} />
              <span style={{ fontSize: 12 }}>Call: 020 5999 1555</span>
              <div style={{ height: 5 }} />
              <span style={{ fontSize: 12 }}>Email: ManeeResidenceCondo@gmail.com</span>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', alignContent: 'center',
                alignItems: 'center'
              }}>
                <span style={{ fontSize: 12 }}></span>
                <img src={isologo} style={{ width: 80, height: 60, marginLeft: 260, marginTop:-50, borderRadius: 3 }} />
              </div>
              <div style={{ height: 0 }} />
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', alignContent: 'center',
                alignItems: 'center'
              }}>
                <div style={{
                  width: 20, height: 20, borderRadius: 3,
                  backgroundColor: 'white',
                  alignItems: 'center', display: 'flex',
                  justifyContent: 'center', alignContent: 'center'
                }}>
                  <BsWhatsapp size={18} color="black" />
                </div>
                <img src={line} style={{ width: 22, height: 20, marginLeft: 5, borderRadius: 3 }} />
                <span style={{ marginLeft: 20, fontSize: 14 }}> 020 5999 1555</span>
                <BsFacebook style={{ marginLeft: 20 }} />

                <span style={{ marginLeft: 5, fontSize: 14 }}>Manee Residence</span>
              </div>
            </div>

          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default App;
